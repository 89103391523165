<template>
  <svg
    class="w-full"
    :class="{'opacity-25': disabled }"
    viewBox="0 0 258 59"
    fill="none"    
  >
    <g id="truck">
      <g v-if="hasTrailer" id="trailer">
        <path
          id="trailer rw"
          d="M246.919 50.1191C246.919 53.923 243.842 57 240.055 57C236.268 57 233.191 53.923 233.191 50.1191C233.191 46.3153 236.268 43.2383 240.055 43.2383C243.842 43.2383 246.919 46.3153 246.919 50.1191Z"
          stroke="#161E2E"
          stroke-width="4"
        />
        <path
          id="trailer fw"
          d="M226.063 50.1191C226.063 53.923 222.987 57 219.2 57C215.413 57 212.336 53.923 212.336 50.1191C212.336 46.3153 215.413 43.2383 219.2 43.2383C222.987 43.2383 226.063 46.3153 226.063 50.1191Z"
          stroke="#161E2E"
          stroke-width="4"
        />
        <rect
          id="trailer load"
          x="156.633"
          y="1.53564"
          width="99.5845"
          height="32.389"
          fill="url(#paint0_linear)"
        />
        <g id="trailer label">
          <g id="arrow">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M219.2 11.4613L219.2 30.7902H216.593L216.593 11.4613H219.2Z"
              fill="black"
            />
            <path
              d="M217.896 4.67004L221.734 11.7225H214.058L217.896 4.67004Z"
              fill="black"
            />
          </g>
          <text
            id="height"
            fill="black"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Inter"
            font-size="15"
            font-weight="500"
            letter-spacing="0em"
          >
            <tspan x="224.413" y="23.1847">{{ qtyHeight2 }}</tspan>
          </text>
          <text
            id="tpu"
            fill="black"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Inter"
            font-size="23"
            font-weight="bold"
            letter-spacing="0em"
          >
            <tspan x="161.847" y="26.0938">{{ tpuCount2 }}</tspan>
          </text>
        </g>
        <path
          id="trailer_2"
          d="M155.851 43.0667H205.122L200.951 50.6416M252.829 43.0667L257 50.6416M149.855 43.0667H148.552M155.851 34.4471V1.01324H257V34.4471H155.851Z"
          stroke="#161E2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g id="main">
        <path
          id="truck rw"
          d="M133.257 50.1191C133.257 53.923 130.18 57 126.393 57C122.606 57 119.53 53.923 119.53 50.1191C119.53 46.3153 122.606 43.2383 126.393 43.2383C130.18 43.2383 133.257 46.3153 133.257 50.1191Z"
          stroke="#161E2E"
          stroke-width="4"
        />
        <path
          id="truck fw"
          d="M31.0652 50.1191C31.0652 53.923 27.9886 57 24.2016 57C20.4147 57 17.3381 53.923 17.3381 50.1191C17.3381 46.3153 20.4147 43.2383 24.2016 43.2383C27.9886 43.2383 31.0652 46.3153 31.0652 50.1191Z"
          stroke="#161E2E"
          stroke-width="4"
        />
        <rect
          id="truck load"
          x="42.9715"
          y="1.53564"
          width="99.5845"
          height="32.389"
          fill="url(#paint1_linear)"
        />
        <g id="trailer label_2">
          <g id="arrow_2">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M106.059 11.4613L106.059 30.7902H103.452L103.452 11.4613H106.059Z"
              fill="black"
            />
            <path
              d="M104.756 4.67004L108.594 11.7225H100.918L104.756 4.67004Z"
              fill="black"
            />
          </g>
          <text
            id="height_2"
            fill="black"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Inter"
            font-size="15"
            font-weight="500"
            letter-spacing="0em"
          >
            <tspan x="111.273" y="23.1847">{{ qtyHeight }}</tspan>
          </text>
          <text
            id="tpu_2"
            fill="black"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Inter"
            font-size="23"
            font-weight="bold"
            letter-spacing="0em"
          >
            <tspan x="48.7067" y="26.0938">{{ tpuCount }}</tspan>
          </text>
        </g>
        <path
          id="truck_2"
          d="M40.6253 50.6416L36.7149 43.0667H112.316L108.666 50.6416M139.688 43.0667L143.338 50.6416M32.0224 13.2897H10.9063L1 30.2678V46.2011H8.29939C14.2616 34.625 19.4957 32.1356 32.0224 34.4471V13.2897ZM42.1894 34.4471H143.338V1.01324H42.1894V34.4471Z"
          stroke="#161E2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="206.426"
        y1="1.53564"
        x2="206.426"
        y2="33.9246"
        gradientUnits="userSpaceOnUse"
      >
        <stop class="transition delay-75" :offset="1 - fill" stop-color="white" stop-opacity="0" />
        <stop class="transition delay-75" :offset="1 - fill" :stop-color="loadColor" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="92.7638"
        y1="1.53564"
        x2="92.7638"
        y2="33.9246"
        gradientUnits="userSpaceOnUse"
      >
        <stop class="transition delay-75" :offset="1 - fill" stop-color="#C4C4C4" stop-opacity="0" />
        <stop class="transition delay-75" :offset="1 - fill" :stop-color="loadColor" />
      </linearGradient>
    </defs>
  </svg>    
</template>
<script>

import { mapGetters } from "vuex"
import { get, find } from "lodash"

export default {

  props: {
    fill: {
      type: Number,
      required: true,
    },
    truckTypeId: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {

    ...mapGetters(['truckTypes']),

    truckType () {
      return find(this.truckTypes, t => t.id === this.truckTypeId)
    },

    qtyHeight () {
      return get(this.truckType, "qtyHeight", 0)
    },

    qtyHeight2 () {
      return get(this.truckType, "qtyHeight2", 0) || this.qtyHeight
    },

    tpuCount () {
      return get(this.truckType, "tpuCount", 0) - this.tpuCount2 // tpuCount is inclusive due to calculations in Ax
    },

    tpuCount2 () {
      return get(this.truckType, "tpuCount2", 0)
    },

    hasTrailer () {
      return this.tpuCount2 > 0
    },

    loadColor () {
      return this.fill > 1 ? "#f56565" : "#13B13F"
    }
  }
}

</script>