<template>
  <div class="flex items-center py-3">
    <div class="w-12 flex-none">
      <img :src="logoUrl" v-if="hasImage" />
    </div>
    <div class="ml-4">{{ text }}</div>
  </div>
</template>

<script>

import { get } from "lodash"

export default {
  name: "p-display-location",

  props: ["item", "config"],

  computed: {
    
    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    },

    direction () {
      return get(this.config, "direction", "")
    },
    
    textProperty () {
      return get(this.config, "textProperty", "locationName")
    },

    hasImage () {
      return !!this.item[`customerImage${this.direction}`]
    },
    
    logoUrl () {
      return this.$appConfig.assetsBaseUrl + this.item[`customerImage${this.direction}`]
    },
    
    text () {
      return this.item[`${this.textProperty}${this.direction}`]
    }

  }

}
</script>

