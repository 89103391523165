<template>
  <div class="p-4 flex-1 overflow-y-auto shadow-inner bg-gray-100 relative">
    <PLoader v-if="loading" />
    <slot />
  </div>
</template>

<script>
export default {
  name: "p-dialog-content",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>