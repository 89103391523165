var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.content)?_c('FontAwesomeIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ 
    content: _vm.content, 
    delay: { 
      show: 100, 
      hide: 100 
    } 
  }),expression:"{ \n    content: content, \n    delay: { \n      show: 100, \n      hide: 100 \n    } \n  }"}],staticClass:"cursor-pointer focus:outline-none",class:{
    'text-white': _vm.color === 'white',
    'text-black': _vm.color === 'black',
    'text-green-500 hover:text-black': _vm.color === 'green',
    'text-green-500 hover:text-black': !_vm.color
  },attrs:{"tabindex":"-1","icon":['fas', 'question-circle']}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }