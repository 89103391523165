
const routes = [
  {
    path: "messages",
    component: () =>
      import(
        /* webpackChunkName: "messagesLayout" */ "@/areas/messages/layouts/MessagesLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "messages",        
        redirect: { name: "messages.Messages" }
      },
      {
        path: "messages/:id?",
        name: "messages.Messages",        
        props: route => ({ id: route.params.id, type: "Message" }),
        component: () =>
          import(
            /* webpackChunkName: "messagesMain" */ "@/areas/messages/views/Messages.vue"
          )
      },
      {
        path: "articles/:id?",
        name: "messages.Articles",
        props: route => ({ id: route.params.id, type: "Article" }),
        component: () =>
          import(
            /* webpackChunkName: "messagesArticles" */ "@/areas/messages/views/Messages.vue"
          )
      },
      {
        path: "guides/:id?",
        name: "messages.Guides",
        props: route => ({ id: route.params.id, type: "Guide" }),
        component: () =>
          import(
            /* webpackChunkName: "messagesGuides" */ "@/areas/messages/views/Messages.vue"
          )
      },
      {
        path: "system/:id?",
        name: "messages.System",
        props: route => ({ id: route.params.id, type: "System" }),
        component: () =>
          import(
            /* webpackChunkName: "messagesGuides" */ "@/areas/messages/views/Messages.vue"
          )
      }
    ]
  },
  {
    path: "message/:id",
    name: "messages.Message",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "messagesRedirect" */ "@/areas/messages/views/Redirect.vue"
      ),
  }
]

export default routes
