<template>
  <div class="text-sm">
    
    <div 
      v-if="label" 
      class="mb-1 text-sm text-gray-700"
      v-html="label">
    </div>

    <div class="font-medium"><slot /></div>
    
  </div>
</template>

<script>
export default {  
  name: "p-input",
  props: {
    label: {
      type: String,
      default: ""
    }
  }
}
</script>
