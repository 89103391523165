<template>
  <div class="leading-tight" v-if="address">
    <div class="font-medium">{{ address.name }}</div>
    <div class="text-xs">{{ address.street }}, {{ address.postalCode }} {{ address.postalName }}, {{ address.countryName }}</div>  
  </div>
</template>
<script>
export default {
  
  name: "PAddressItem",
  
  props: {
    address: {
      type: Object,
      required: false
    }
  }

}
</script>