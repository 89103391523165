<template>
  <div 
    class="flex-0 p-4 flex items-center border-t bg-white rounded-b-lg border-gray-300 whitespace-no-wrap"
    :class="{
      'justify-between': split,
      'justify-end': !split,
      'overflow-x-auto': scrollable
      }"
  >
    <slot />
  </div>
</template>

<script>
export default {

  name: "p-dialog-actions",

  props: {
    split: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: true
    }
  }

}
</script>