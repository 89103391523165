<template>
  <div class="bg-red-200 border-2 border-red-300 mt-10 rounded-lg text-red-800 p-4 text-center">
      {{ text }}
  </div>
</template>

<script>
  export default {
    name: "p-error",
    props: {
      text: {
        type: String,
        default: null
      }
    }
  }
</script>