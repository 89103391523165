
export function hint (el, binding) {
  
  var div = el.querySelector(".sr-hint")

  if (div) {
    div.parentNode.removeChild(div)
  }

  if (binding.value.show) {
    let newDiv = document.createElement("div")
    newDiv.className = "sr-hint"
    newDiv.style = "position:absolute;background-color:black;color:white;padding:2px 5px;line-height:1;border:2px solid white;font-size:12px;font-weight:500;z-index:19"
    newDiv.innerHTML = binding.value.text
    el.prepend(newDiv)
  }  
}