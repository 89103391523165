const routes = [
  {
    path: "balance",
    name: "balance",
    component: () =>
      import(
        /* webpackChunkName: "balanceBalance" */ "@/areas/_balance/views/Balance.vue"
      )
  }
]

export default routes
