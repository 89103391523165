const routes = [
  {
    path: "reports",
    component: () =>
      import(
        /* webpackChunkName: "reportsLayout" */ "@/areas/reports/layouts/ReportsLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "reports",
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/areas/reports/views/Reports.vue"
          )
      },
      {
        path: "commisions",
        name: "reports.Commisions",
        component: () =>
          import(
            /* webpackChunkName: "reportsCommisions" */ "@/areas/reports/views/Commisions.vue"
          )
      },
      {
        path: "locations",
        name: "reports.Locations",
        component: () =>
          import(
            /* webpackChunkName: "reportsCommisions" */ "@/areas/reports/views/Locations.vue"
          )
      },
      {
        path: "invoices/:type/:id?",
        name: "reports.Invoices",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "reportsInvoices" */ "@/areas/reports/views/Invoices.vue"
          )
      },
      {
        path: "purchases",
        name: "reports.Purchases",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "reportsTransactions" */ "@/areas/reports/views/Purchases.vue"
          )
      },
      {
        path: "transactions",
        name: "reports.Transactions",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "reportsTransactions" */ "@/areas/reports/views/Transactions.vue"
          )
      },
      {
        path: "pooling",
        name: "reports.Pooling",
        component: () =>
          import(
            /* webpackChunkName: "reportsPooling" */ "@/areas/reports/views/Pooling.vue"
          )
      },
      {
        path: "users",
        name: "reports.Users",
        component: () =>
          import(
            /* webpackChunkName: "reportsUsers" */ "@/areas/reports/views/Users.vue"
          )
      },
    ]
  }
]

export default routes
