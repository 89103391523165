<template>
    <select class="h-10 border-gray-400 form-select" v-model="selectedIndex">
      <option :value="index" v-for="(product, index) in products" :key="index">
        {{ product.name }}
      </option>
    </select>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import { get, pick, findIndex } from "lodash"

export default {
  name: "p-products-picker",
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedIndex: -1,
      products: []
    }
  },
  computed: {
    ...mapGetters([
      "location"
    ])
  },
  async created () {
    this.products = await http.get("/products", { params: { all: true } })
    this.selectedIndex = findIndex(this.products, product => 
      product.id === get(this.value, "id") &&
      product.qualityId === get(this.value, "qualityId") &&
      product.treatmentId === get(this.value, "treatmentId")
    )
  },
  watch: {
    selectedIndex: function (val) {        
      this.$emit("input", val >= 0 ? pick(this.products[val], "id", "qualityId", "treatmentId") : null)
    }
  }
}
</script>

