import http from "@/http"

const api = {
  async userGet(userId) {
    return await http.get("user", { id: userId })
  },
  async userPost(userId, name, phone, email, language) {
    return await http.post("user", {
      userId: userId,
      name: name,
      phone: phone,
      email: email,
      language: language 
    })
  },
  async userPasswordPost(userId, oldPassword, newPassword) {
    return await http.post("userPassword", {
      userId: userId,
      oldPassword: oldPassword,
      newPassword: newPassword
    })
  },
}

export default api
