
/*
 * This plugin is installed on the instance and the methods can be used through 
 * this.$ syntax. It contains helper for common functionality in the SR API
 */

function install(Vue) {
  Vue.prototype.$formatApiError = function (input) {
    let error = input.inner
    if (error.response) {
      if (error.response.data && error.response.data.error) {
        return error.response.data.error.message
      }
      return error.response
    }
    return ''
  }
}

export default install
