export const apiOptions = {
  NONE: 0
}

export const apiStatus = {
  API: 1,
  REQUEST: 2,
  ACCOUNT: 4,
  SERVICES: 8,
  APPDOMAIN: 256,
  PROCESS: 512,
  SERVER: 1024,
  AVERAGES: 2048,
  STACKTRACE: 4096,
  DEBUG: 268435456
}

export const apiFormat = {
  VERB: 1,
  TYPED: 2,
  COMMON: 4,
  DEFAULT: 8,
  EXTENDED: 16,
  FULL: 32,
  NESTED: 64,
  ALL: 4096
}

export const customerStatus = {
  INACTIVE: 0,
  ACTIVE: 1
}

export const customerType = {
  OTHER: 0,
  CUSTVEND: 1,
  MERCHANT: 2,
  DETAIL: 3,
  WABA: 4
}

export const locationStatus = {
  NEW: 0,
  ACTIVE: 1,
  CLOSED: 2,
  FINISHED: 3
}

export const locationType = {
  UNKNOWN: 0,
  WABA: 1,
  CUSTVEND: 2,
  RETAIL: 3,
  SHOP: 4,
  EXTERNAL: 5
}

export const userStatus = {
  INACTIVE: 0,
  ACTIVE: 1
}

export const userType = {
  UNKNOWN: 0,
  NORMAL: 1,
  CUSTOMER: 2,
  CHAIN: 3,
  WABA: 4
}

export const userLoginStatus = {
  NONE: 0,
  ADUSERFOUND: 1,
  ADUSERNOTFOUND: 2,
  ADPASSWORDCORRECT: 4,
  ADPASSWORDINCORRECT: 8,
  ADACCESSALLOWED: 16,
  ADACCESSDENIED: 32,
  ADERROR: 64,
  AXUSERINFOFOUND: 256,
  AXUSERINFONOTFOUND: 512,
  AXSYSUSERINFOFOUND: 1024,
  AXSYSUSERINFONOTFOUND: 2048,
  AXSYSCOMPANYUSERINFOFOUND: 4096,
  AXSYSCOMPANYUSERINFONOTFOUND: 8192,
  AXUSERACTIVE: 65536,
  AXUSERINACTIVE: 131072,
  AXUSERISWABA: 262144,
  AXUSERISNOTWABA: 524288,
  AXUSERLOCATIONSET: 1048576,
  AXUSERLOCATIONNOTSET: 2097152,
  AXUSERLOCATIONFOUND: 4194304,
  AXUSERLOCATIONNOTFOUND: 8388608,
  AXUSERLOCATIONACTIVE: 16777216,
  AXUSERLOCATIONINACTIVE: 33554432,
  AXCUSTOMERSET: 268435456,
  AXCUSTOMERNOTSET: 536870912,
  AXCUSTOMERFOUND: 1073741824,
  AXCUSTOMERNOTFOUND: 2147483648,
  AXCUSTOMERACTIVE: 4294967296,
  AXCUSTOMERINACTIVE: 8589934592,
  AXCUSTOMERLOCATIONSET: 17179869184,
  AXCUSTOMERLOCATIONNOTSET: 34359738368,
  AXCUSTOMERLOCATIONFOUND: 68719476736,
  AXCUSTOMERLOCATIONNOTFOUND: 137438953472,
  AXCUSTOMERLOCATIONACTIVE: 274877906944,
  AXCUSTOMERLOCATIONINACTIVE: 549755813888,
  AXLOGONAXSUCCEEDED: 1099511627776,
  AXLOGONAXFAILED: 2199023255552
}

export const productType = {
  UNKNOWN: 0,
  PALLET: 1,
  BOX: 2,
  CAGE: 3,
  EXPIRED: 4
}

export const productMaterial = {
  UNKNOWN: 0,
  WOOD: 1,
  PLASTIC: 2
}

export const productVisibility = {
  HIDDEN: 0,
  VISIBLE: 1,
  FAVORITE: 2
}

export const transactionStatus = {
  WEB: 0,
  ORDERED: 1,
  CONFIRMEDWABA: 2,
  PICKED: 3,
  SENT: 4,
  CONFIRMED: 5,
  NOTAGREED: 6,
  CANCELLEDWABA: 7,
  REPLACED: 8,
  WAITFORSORT: 9,
  TREATMENT: 10,
  WAITFORCONFIRMATION: 11,
  RECEIVEDSORT: 12,
  WAITFORACCEPT: 13,
  WAITFORAGREEMENT: 14
}

export const transactionType = {
  UNKNOWN: "??",
  ORDER: "TO",
  DISPATCH: "HE",
  DELIVERY: "OV",
  AVAILABLE: "TI",
  RETURN: "RO",
  TRANSFER: "OO",
  INDUSTRY: "IO",
  BALANCE: "TDA",
  EXTERNAL: "OUK"
}

export const reportLevel = {
  LOCATION: 0,
  CUSTOMER: 1,
  CHAIN: 2,
  CUSTOMERTYPE: 3,
  GLOBAL: 4
}
