<template>
  <div    
    v-shortkey.push="['alt']"    
    @shortkey="onAlt"    
    :class="themeClassName"
  >  
    <div class="flex flex-col h-screen" v-if="isReady">
      
      <PUserContext :mobileLinks="links" />

      <!-- top -->
      <div class="flex-shrink-0 items-center justify-between bg-white px-4 py-5 shadow-inner hidden md:flex">
        
        <!-- left -->
        <div class="flex items-center">
          
          <!-- main nav -->
          <router-link
            class="px-3 py-2 text-sm leading-none whitespace-no-wrap font-semibold app-link rounded"
            :class="{ 'ml-2': index > 0 }"
            @shortkey.native="onShortkey(link)"
            v-hint="{ show: hintsVisible, text: index + 1 }"
            v-shortkey.push="['alt', index + 1]"
            v-for="(link, index) in links"
            :key="index"
            :to="{ name: link.route }"
            active-class="app-link-active"
          >
            <FontAwesomeIcon 
              :icon="['far', link.icon]" 
              v-if="link.icon" 
              size="lg" 
            />

            <div class="inline-block relative">
              <span class="ml-1 hidden xl:inline" v-html="link.name"></span>
              <div v-if="link.badge" class="badge">{{ link.badge }}</div>
            </div>
            
          </router-link>
        </div>
        <!-- /left -->

        <div class="flex items-center space-x-4">          
          <router-link 
            class="text-2xl text-green-500 hover:text-black focus:outline-none focus:text-black"                
            :to="{ name: 'messages' }">
            <FontAwesomeIcon :icon="['fas', 'question-circle']" />
          </router-link>        
          <PAlertsDropdown />
          <MenuDropdown />
        </div>        
        
      </div>
      <!-- /top -->

      <!-- body -->
      <div class="flex-1 flex overflow-hidden">
          <router-view></router-view>
      </div>
    </div>

    <div 
      v-else
      class="flex justify-center items-center h-screen bg-gray-100 text-gray-500"
    >
      <FontAwesomeIcon 
        :icon="['far', 'spinner']" 
        size="lg" 
        spin 
      />
    </div>

    <portal-target name="modals" multiple />
    <portal-target name="dialogs" multiple />

    <PNotifications></PNotifications>    

  </div>
</template>

<script>
import http from "@/http"
import { find, filter, some } from "lodash"
import { mapGetters } from "vuex"
import PUserContext from "@/components/PUserContext"
import MenuDropdown from "@/components/MenuDropdown"
import PAlertsDropdown from "@/components/PAlertsDropdown"
import PNotifications from "@/components/PNotifications"
import LocaleService from "@/services/LocaleService"
import { getTheme } from "@/config"
import { 
  setIntervalAsync, 
  clearIntervalAsync  
} from "set-interval-async/dynamic"

const theme = getTheme();

export default {
  
  name: "MainLayout",
  
  components: {
    PUserContext,
    MenuDropdown,
    PAlertsDropdown,
    PNotifications
  },
  
  data() {    
    return {      
      hasLogo: false,
      disagreements: [],
      timer: null,
      showHint: false
    }
  },

  computed: {

    ...mapGetters([
      "isReady", 
      "user",
      "loginUser",
      "location", 
      "customer",
      "language",
      "webtaskUsers",
      "alerts",
      "hintsVisible",
      "errors"
    ]),

    themeClassName () {
      return theme.themeClassName
    },

    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl
    },

    supportAlertCount () {
      return filter(this.alerts, alert => alert.source === "Support").length
    },

    links () {

      let links = []

      links.push({ route: "home", name: this.$tk("MainLayout.Startpage"), icon: "home" })

      if (this.hasWebtaskUser("ORDER") && !this.location.isSmartRetur) {
        links.push({ route: "orders.Orders", name: (this.location.poolingGroupId || this.location.isCommission) ? this.$tk("MainLayout.Orders") : this.$tk("MainLayout.Buy"), icon: "shopping-cart" })
      }

      if (this.hasWebtaskUser("DISPATCH") && !this.location.isSmartRetur) {
        links.push({ route: "dispatch.Dispatch", name: (this.location.poolingGroupId || this.location.isCommission) ? this.$tk("MainLayout.Dispatch") : this.$tk("MainLayout.Sell"), icon: "pallet" })
      }

      if (this.hasWebtaskUser("SHIPMENT") && (this.location.isPooling || this.location.isCommission || this.location.isSmartRetur)) {
        links.push({ route: "delivery.Delivery", name: this.$tk("MainLayout.Delivery"), icon: "truck-loading", badge: this.deliveryDisagreements })
      }
      
      if (this.hasWebtaskUser("RECEPTION")) {
        links.push({ route: "reception.Reception", name: this.$tk("MainLayout.Reception"), icon: "forklift", badge: this.receptionDisagreements })
      }

      if(this.hasWebtaskUser("EXPEDITION") && (this.location.isSmartRetur || (!this.location.isSmartRetur && this.location.isDispatch))) {      
        links.push({ route: "expedition.Expedition", name: this.$tk("MainLayout.Expedition"), icon: "truck", badge: this.expeditionDisagreements })
      }

      if ((this.hasWebtaskUser("CONTAINER") && !this.location.isSmartRetur && this.location.isContainerActive) || (this.location.isContainerActive && this.loginUser.isGlobalAdmin)) {
        links.push({ route: "containers.Rentals", name: this.$tk("MainLayout.Container"), icon: "container-storage" })
      }

      if (this.hasWebtaskUser("REPORTS")) {
        links.push({ route: "reports", name: this.$tk("MainLayout.Reports"), icon: "chart-line" })
      }

      links.push({ route: "support.Tickets", name: this.$tk("MainLayout.Support"), icon: "life-ring", badge: this.supportAlertCount })

      return links
    },

    deliveryDisagreements () {
      return filter(this.disagreements, disagreement => {
        return disagreement.locationIdFrom === this.location.id &&
               some(["GL", "OO"], id => disagreement.transactionTypeId === id)
      }).length
    },

    receptionDisagreements () {
      return filter(this.disagreements, disagreement => {
        return disagreement.locationIdTo === this.location.id
      }).length
    },

    expeditionDisagreements () {
      return filter(this.disagreements, disagreement => {
        return disagreement.locationIdFrom === this.location.id && 
        some(["TO", "HE", "RO"], id => disagreement.transactionTypeId === id)
      }).length
    }

  },

  methods: {
    
    hasWebtaskUser (id) {
      return this.webtaskUsers.length === 0 || find(this.webtaskUsers, webTaskUser => webTaskUser.taskId === id)
    },

    async getDisagreements() {
      this.disagreements = await http.get("Orders", {
        params: {
          transactionStatus: 6,
          include: "id,transactionTypeId,locationIdFrom,locationIdTo"          
        }
      })
    },

    navigate (route) {
      this.$router.push({ name: route })
    },

    onShortkey (route) {  
      if (route.route !== this.$route.name) {
        this.$router.push({ name: route.route })
      }
    },

    onAlt () {
      this.$store.commit('toggleHintsVisible')
    }

  },

  async created () {

    await this.$store.dispatch("init")
    
    if (this.errors.length === 0) {

      this.$i18n.locale = this.language
      this.$store.commit("setIsReady", true)
      
      // gdpr
      if (this.loginUser.isGlobalAdmin === false && this.user.confirmedGdpr === false) {
        this.$router.push({ name: "gdpr" })
      }      

      else {
        
        // first
        await this.getDisagreements()

        // subsequent
        this.timer = setIntervalAsync(async () => {
          await this.getDisagreements()
        }, 30000)

      }  

    } else {

      this.$router.push({ name: "error" })

    }

    // const handleFocus = () => {      
    //   this.$store.commit("setHintsVisible", false)                    
    // }
    
    // window.addEventListener("focus", handleFocus)

    // this.$once('hook:beforeDestroy', () => {
    //   window.removeEventListener("focus", handleFocus)
    // })

  },

  mounted () {
    const locale = LocaleService.getLocale()
    document.documentElement.setAttribute('lang', locale.substr(0, 2));
  },

  async beforeDestroy () {
    if (this.timer) {
      await clearIntervalAsync(this.timer)
    }
  }

}
</script>

<style>
.app-link.app-link-active {
  @apply bg-green-500 text-white shadow-inner;
}

.app-link.app-link-active:focus {
  @apply outline-none;
}

.app-link:not(.app-link-active) {
  @apply bg-white text-green-500;
}

.app-link:not(.app-link-active) svg {
  @apply text-green-500;
}

.app-link:not(.app-link-active):hover {
  @apply bg-green-100;
}

.app-link:not(.app-link-active):focus {
  @apply outline-none bg-green-100 text-green-600;
}

.badge {
  @apply bg-red-500 text-white font-semibold rounded-full absolute top-0 right-0 w-4 h-4 leading-none flex items-center justify-center;  
  margin-top: -0.65rem;
  margin-right: -0.65rem;
  font-size: 10px;
}
</style>
