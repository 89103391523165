<template>
  <PSelect    
    :items="filteredProducts"    
    :value="value"
    :focus="focus"
    :label="label"
    :searchable="true"
    @input="onInput"
    itemText="productName"
    itemValue="key"
    v-bind="$props"
    v-slot="{ item }" 
  >
    <div class="flex text-left items-center h-12">
      <img :src="`${assetsBaseUrl}/media.ashx?${item.image}&template=product-image-xsmall`" />
      <div class="pl-4">
        <div class="text-sm font-semibold">{{ item.productName }}</div>        
        <div class="text-xs" v-if="!grouped && (!item.treatmentIsDefault || !item.qualityIsDefault)">
          {{ item.qualityName }}
          {{ item.treatmentIsDefault ? "" : item.treatmentName }}
        </div>
      </div>
    </div>
  </PSelect>

</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import { 
  find,
  filter, 
  some,
  map,
  forEach
} from "lodash"

const key = function (o) {
  return `${o.productId}:${o.treatmentId}:${o.qualityId}`.toUpperCase()
}

export default {
  name: "p-product-picker",
  props: {
    value: {
      type: String,
      required: true
    },
    locationId: {
      type: String,
      required: true
    },
    pooling: {
      type: Boolean,
      default: false
    },
    grouped: {
      type: Boolean,
      default: false
    },
    exclude: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    all: {
      type: Boolean,
      default: true
    },
    focus: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      products: [],
    }
  },  
  computed: {
    
    ...mapGetters(["location"]),

    filteredProducts () {

      return filter(this.products, product => {
        
        const excluded = some(this.exclude, item => 
          item.id === product.id &&
          item.treatmentId === product.treatmentId &&
          item.qualityId === product.qualityId          
        )

        return !excluded        
      })
    },

    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl
    }

  },
  methods: {

    async load (locationId) {
      
        let params = {
          locationId
        }

        if (this.pooling) {
          params.isPooling = true
        }

        if (this.grouped) {
          params.grouped = true
        }

        let products = await http.get("AvailableProducts", { params })

        if (this.grouped) {
          forEach(products, p => {
            p.productId = p.id
            p.productName = p.name
          })
        }

        const keyed = map(products, p => {
          return {
            ...p,            
            key: key(p)
          }
        })

        this.products = keyed

        this.$emit("loaded", keyed)        
    },

    onInput (value) {      
      if (value) {                                         
        const product = find(this.filteredProducts, p => key(p) === value)        
        this.$emit("select", product)
      }
    }
  },

  watch: {
    locationId: {
      handler: function (val) {
        if (val) {
          this.load(val)
        }
      }, immediate: true
    }
  }

}
</script>
