import Vue from "vue"
import VueRouter from "vue-router"
import Login from "../views/Login"
import MainLayout from "../layouts/MainLayout"
import ExternalLayout from "../layouts/ExternalLayout"
import TokenService from "../services/TokenService"

import homeRoutes from "../areas/home/routes"
import orderRoutes from "../areas/orders/routes"
import containerRoutes from "../areas/containers/routes"
import dispatchRoutes from "../areas/dispatch/routes"
import deliveryRoutes from "../areas/delivery/routes"
import receptionRoutes from "../areas/reception/routes"
import expeditionRoutes from "../areas/expedition/routes"
import reportsRoutes from "../areas/reports/routes"
import supportRoutes from "../areas/support/routes"
import messagesRoutes from "../areas/messages/routes"

import globalRoutes from "../areas/_global/routes"
import companyRoutes from "../areas/_company/routes"
import balanceRoutes from "../areas/_balance/routes"

import palletReceiptsRoutes from "../apps/pallet-receipts/routes"

import { getTheme } from "../config"
const theme = getTheme()

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: ExternalLayout,
    children: [
      {
        path: "",
        name: "external",
        redirect: { name: "login" }
      },
      {
        path: "login",
        name: "login",
        component: Login
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () =>
          import(
                /* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword.vue"
          )
      },
      {
        path: "reset-password",
        name: "reset-password",
        component: () =>
          import(
                /* webpackChunkName: "reset-password" */ "@/views/ResetPassword.vue"
          )
      },
      {
        path: "new-customer",
        name: "new-customer",
        component: () =>
          import(
                /* webpackChunkName: "new-customer" */ "@/views/NewCustomer.vue"
          )
      },
      {
        path: "gdpr",
        name: "gdpr",
        component: () =>
          import(
                /* webpackChunkName: "gdpr" */ "@/views/Gdpr.vue"
          )
      },
      {
        path: "agreement",
        name: "agreement",
        component: () =>
          import(
                /* webpackChunkName: "agreement" */ "@/views/Agreement.vue"
          )
      },
      {
        path: "visitors",
        name: "visitors",
        component: () =>
          import(
                /* webpackChunkName: "new-customer" */ "@/views/Visitors.vue"
          )
      },
    ]
  },

  {
    path: "/app",
    component: MainLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: "",
        name: "app",
        redirect: { name: "home" }
      },
      {
        path: "controls",
        name: "controls",
        meta: { title: "Controls" },
        component: () =>
          import(
            /* webpackChunkName: "controls" */ "@/views/Controls.vue"
          )
      },
      {
        path: "api",
        name: "api",
        meta: { title: "API" },
        component: () =>
          import(
            /* webpackChunkName: "controls" */ "@/views/Api.vue"
          )
      },
      {
        path: "/account/login",
        name: "old-login",
        redirect: { name: "login" }
      },


      ...homeRoutes,
      ...orderRoutes,
      ...containerRoutes,
      ...dispatchRoutes,
      ...deliveryRoutes,
      ...receptionRoutes,
      ...expeditionRoutes,
      ...reportsRoutes,
      ...supportRoutes,
      ...messagesRoutes,

      ...globalRoutes,
      ...companyRoutes,
      ...balanceRoutes,
    ]
  },

  ...palletReceiptsRoutes,

  {
    path: "/error",
    name: "error",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/Error.vue"
      )
  },

  // old urls
  {
    path: "*",
    name: "not-found",
    redirect: { name: "login" }
  }

]

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
})

router.beforeEach((to, from, next) => {

  document.title = to.meta.title ? `${to.meta.title} - ` + theme.title : theme.title

  if (location.hostname.toLowerCase().indexOf("digipall2") !== -1 && to.path.indexOf("/app") === 0) {
    next({
      path: "/pallet-receipts-inv"
    })
  }
  else if (location.hostname.toLowerCase().indexOf("digipall") !== -1 && to.path.indexOf("/app") === 0) {
    next({
      path: "/pallet-receipts"
    })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!TokenService.getToken()) {
      next({
        path: "/",
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
