const LOCALE_SE = "sv-SE"
const LOCALE_DK = "da-DK"
const LOCALE_FI = "fi-FI"
const LOCALE_NO = "nb-NO"

const LocaleService = {
  
  getLocale() {

    let locale = localStorage.getItem("locale")

    if (!locale) {

      let suffix = ""

      const index = location.hostname.lastIndexOf(".")

      if (index > 0) {
        suffix = location.hostname.substr(index).toLocaleLowerCase()
      }

      switch (suffix) {
        case ".se": 
          locale = LOCALE_SE
          break
        case ".dk":
          locale = LOCALE_DK
          break
        case ".fi":
          locale = LOCALE_FI
          break
        default:
          locale = LOCALE_NO
      }      
    }

    return locale    
  },

  setLocale (locale) {
    localStorage.setItem("locale", locale)
  }

}

export default LocaleService