import {
  nb 
 } from "date-fns/locale"

import {
  format,
  formatDistance
 } from "date-fns"

export function date (s) {
  return s ? s.substr(0, s.indexOf("T")) : ""
}

export function dateIfToday (s) {
  const today = format(new Date(), "yyyy-MM-dd")
  const date = s ? s.substr(0, s.indexOf("T")) : ""
  return date === today ? date : ""
}

export function time (s) {
  return s ? s.substr(s.indexOf("T") + 1, 5) : ""
}

export function dateAndTime (s) {
  return `${date(s)} ${time(s)}`
}

export function ago (dt) {  
  return formatDistance(
    new Date(dt),
    new Date(), {
      locale: nb
    }
  )
}
