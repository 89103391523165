const routes = [
  {
    path: "orders",    
    component: () =>
      import(
        /* webpackChunkName: "ordersLayout" */ "@/areas/orders/layouts/OrdersLayout.vue"
      ),
    children: [
      {
        path: "",  
        name: "orders",
        redirect: { name: "orders.Orders" }
      },
      {
        path: "orders/:id?",
        name: "orders.Orders",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ordersOrders" */ "@/areas/orders/views/Orders.vue"
          )
      },
      {
        path: "scheduled",
        name: "orders.Scheduled",
        component: () =>
          import(
            /* webpackChunkName: "ordersScheduled" */ "@/areas/orders/views/Scheduled.vue"
          )
      }
    ]
  }
]

export default routes
