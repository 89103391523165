<template>
  <div
    class="p-4 text-sm cursor-pointer hover:bg-orange-200 group"
    :class="{
      'border-b border-dashed border-gray-300': border
    }"
  >

    <router-link
      class="relative block pl-6"
      :to="{ name: routeName, params: routeParams }"
    >
      <div class="absolute left-0 top-0">
        <FontAwesomeIcon :icon="['far', icon]" />
      </div>

      <div class="font-semibold text-green-500 underline group-hover:text-black">{{ alert.title }}</div>
      <p class="mt-1">{{ alert.text }}</p>

    </router-link>

    <!-- <template v-else>
      <div class="text-xs font-medium">{{ alert.updated | dateAndTime }}</div><br/>
      source: {{ alert.source }}<br/>
      reason: {{ alert.reason }}<br/>
      title: {{ alert.title }}<br/>
      text: {{ alert.text }}<br/>
      sourceId: {{ alert.sourceId }}
    </template>
-->


  </div>

</template>

<script>

export default {

  props: {
    alert: {
      type: Object,
      required: true
    },
    border: {
      type: Boolean,
      default: true
    }
  },

  computed: {

    source() {
      return this.alert.source
    },

    icon() {
      switch (this.source) {
        case "Support": return "life-ring"
        case "Message": return "newspaper"
        case "Order": return "pallet"
        case "Invoice": return "file-invoice"
        default: return "exclamation-circle"
      }
    },

    routeName() {
      switch (this.source) {
        case "Support": return "support.Tickets"
        case "Message": return "messages.Message"
        case "Order": return "orders"
        case "Invoice": return "reports.Invoices"
        case "Log": return "global.Log"
        case "System": return "messages.System"
        default: return "home"
      }
    },

    routeParams() {

      let params = { id: this.alert.sourceId }

      if (this.alert.source === "Invoice") {
        params.type = "customer"
      }

      return params
    }
  }
}
</script>