<template>
  <table class="data-table w-full text-xs">
    <thead>
      <tr>
        <th 
          v-for="(col, index) in cols" 
          :key="index"
          :width="col.width"
          :align="col.hasOwnProperty('align-header') ? col.align-header : false"   
          class="leading-4 select-none font-medium  text-white"
          :class="{ 
            'bg-gradient-to-b hover:from-gray-400 hover:to-gray-500 cursor-pointer': col.sortable, 
            'bg-gradient-to-b from-gray-400 to-gray-500': col.value === currentOrderByField, 
            'bg-gradient-to-b from-gray-500 to-gray-600': col.value !== currentOrderByField
          }"
          @click="orderBy(col)"            
        >
          <div class="p-5 flex items-center justify-between">
            <div v-html="col.text"></div>
            <font-awesome-icon 
              class="ml-2"
              v-if="col.sortable"              
              :icon="orderByClasses(col)"
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody name="p-table" is="transition-group">
      <p-table-row class="p-table-item" @select="select" @delete="remove" v-for="(item, index) in sortedItems" :record="item" :cols="cols" :hover="hover" :class="{ 'bg-gray-200': index % 2 === 0 }" :key="item.id"></p-table-row>
    </tbody>
  </table>
</template>

<script>
import { orderBy } from "lodash";
import PTableRow from "@/components/PTableRow"

export default {
  name: "PTable",
  props: {
    cols: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    hover: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PTableRow,
  },
  data() {
    return {
      currentOrderByField: null,
      orderByDirection: null,
      sortedItems: this.data
    };
  },
  watch: {
    data(value) {
      this.sortedItems = value
    }
  },
  methods: {
    select(item) {
      this.$emit("select", item);
    },
    remove(item) {
      this.$emit("delete", item)
    },
    orderByClasses(col) {
      let classes = ['far']

      if (this.currentOrderByField == col.value){
        if (this.orderByDirection === "desc"){
          classes.push('sort-down')
        } else {
          classes.push('sort-up')
        } 
      } else {
        classes.push('sort')
      } 
      return classes
    },
    orderBy(col) {
      if (!col.sortable) {
        return
      }
      if (col.value === this.currentOrderByField) {
        this.orderByDirection = this.orderByDirection === "desc" ? "asc" : "desc"
      }
      else {
        this.orderByDirection = "desc"
      }
      this.currentOrderByField = col.value

      let sorted = orderBy(this.data,
        (element) => {
          if (col.type === "date") {
            return new Date(element[col.value]);
          } else if (col.type === "id") {
            return +element[col.value].split("-")[1]
          } else {
            return element[col.value] !== null ? element[col.value] : ''; // This is needed to make orderby sort properly
          }
        },
        this.orderByDirection
      );
      this.sortedItems = sorted;
    },
  },
};
</script>

<style scoped>
 table {
    border-spacing: 0;
    border: 0;    
    font-size: theme('fontSize.xs');
    color: theme('colors.gray.700');
  }

  th { 
    top: 0;
    margin: 0; 
    position: sticky;
    padding: 0;
    line-height: theme('lineHeight.none');    
    /* border-bottom: 1px solid theme('colors.gray.300'); */
    /* border-right: 1px solid theme('colors.gray.300'); */
    /* box-shadow: 0 1px 0 theme('colors.gray.200'); */
  }

  .p-table-item {
    transition: all 0.2s;
  }
  .p-table-item > * {
    transition: all 0.2s;
    overflow: hidden;
  }
  .p-table-enter,
  .p-table-leave-to {
    line-height: 0 !important;
    
  }
  .p-table-enter > *,
  .p-table-leave-to > * {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    line-height: 0px !important;
    max-height: 0px;
  }

</style>
