<template>
  <div class="inline-flex items-center">
    
    <span v-html="$tk(k)"></span>
    
    <PTooltip 
      v-if="tooltip" 
      class="ml-1 text-xs" 
      :content="tooltip" 
    />

  </div>
</template>

<script>
export default {

  props: {
    k: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      tooltip: ""
    }
  },

  created () {
    
    const tooltipKey = `${this.k}_Tooltip`

    if (this.$i18n.te(tooltipKey)) {
      this.tooltip = this.$i18n.t(tooltipKey)
    }

  }
}
</script>