const routes = [
  {
    path: "reception",
    component: () =>
      import(
        /* webpackChunkName: "receptionLayout" */ "@/areas/reception/layouts/ReceptionLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "reception",
        redirect: { name: "reception.Reception" }
      },
      {
        path: "reception/:id?",
        name: "reception.Reception",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "receptionReception" */ "@/areas/reception/views/Reception.vue"
          )
      },
    ]
  }
]

export default routes
