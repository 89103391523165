<template>
  <div class="relative">      

    <PClickOutside :do="() => isOpen = false">      
      <button
        @click="onToggle"
        class="relative text-green-500 hover:text-black focus:outline-none focus:text-black"
        title="Varsler"
      >
        <div class="badge" v-if="filteredAlerts.length > 0">{{ filteredAlerts.length }}</div>
        <font-awesome-icon class="text-2xl" :icon="['far', 'bell']" />
      </button>      
    </PClickOutside>

    <transition
      enter-class="transform opacity-0 scale-95"
      enter-active-class="transition ease-out duration-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div 
        v-if="isOpen" 
        class="origin-top-right mt-1 border border-gray-300 absolute z-20 right-0 rounded overflow-x-hidden bg-white shadow-lg w-96 max-h-96 overflow-y-auto"
      >
        <PAlertItem 
          :alert="alert"
          :border="index < filteredAlerts.length - 1"  
          v-for="(alert, index) in filteredAlerts"
          :key="index" 
        />
      </div>
    </transition>

    <PConfirm v-if="mustReadAlerts.length > 0 && !whitelistRoute" :title="mustReadTitle">
      <PAlertItem 
        :alert="alert"
        :border="index < mustReadAlerts.length - 1" 
        v-for="(alert, index) in mustReadAlerts"
        :key="index" 
      />
      <div slot="buttons"></div>
    </PConfirm>

  </div>
</template>

<script>

import { 
  setIntervalAsync, 
  clearIntervalAsync  
} from "set-interval-async/dynamic"
import { mapGetters } from "vuex"
import PAlertItem from "./PAlertItem"
import { filter } from "lodash"

export default {
  
  components: {
    PAlertItem
  },

  data () {
    return {
      isOpen: false,
      timer: null
    }
  },

  computed: {
    ...mapGetters(["location", "alerts", "user", "loginUser"]),
    
    filteredAlerts () {
      return filter(this.alerts, a => 
        !(a.source === "Order") &&
        !(a.source === "Support") &&          
        !(a.source === "Message" && a.data.read)
      )
    },

    mustReadAlerts () {
      const userIsLoginUser = this.user.id === this.loginUser.id
      return userIsLoginUser ? filter(this.filteredAlerts, a => !a.data.read && a.data.mustRead) : []
    },

    whitelistRoute () {
      return this.$route.name.indexOf("messages") === 0 ||
             this.$route.name.indexOf("api") === 0 ||
             this.$route.name.indexOf("global") === 0
    },

    mustReadTitle () {
      return this.mustReadAlerts.length > 1 
              ? this.$tk("PAlertsDropdown.ImportantMessages") 
              : this.$tk("PAlertsDropdown.ImportantMessage") 
    }

  },

  methods: {
    onToggle () {
      this.isOpen = !this.isOpen
    }
  },

  async created () {
    
    await this.$store.dispatch("getAlerts")

    this.timer = setIntervalAsync(async () => {
      await this.$store.dispatch("getAlerts")
    }, 30000)

  },

  async beforeDestroy () {
    if (this.timer) {
      await clearIntervalAsync(this.timer)
    }
  },

  watch: {
    events: async function () {
      await this.$store.dispatch("getAlerts")
    }
  }

}
</script>