<template>
  <a 
    href    
    class="underline cursor-pointer" 
    @click.prevent="onClick"
    :class="classes"
  >
    <div class="flex items-center" v-if="icon">
      <FontAwesomeIcon :icon="['far', icon]" :spin="iconSpin" />
      <div class="ml-1"><slot /></div>
    </div>
    <slot v-else />
  </a>
</template>

<script>
export default {

  props: {
    icon: {
      type: String,
      required: false
    },
    iconSpin: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      validation: (value) => {
        return ["danger"].indexOf(value) !== -1
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }    
  },

  computed: {
    classes () {
      return {
        "text-red-300": this.color === "danger" && this.disabled,
        "text-red-600": this.color === "danger" && !this.disabled,
        "cursor-not-allowed": this.disabled
      }
    }
  },

  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
  
}
</script>