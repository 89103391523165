<template>
  <div class="py-1">

    <template v-if="item.transactionTypeId === 'TO'">

      <template v-if="item.customerGroupFrom === 'X'">
        <span v-tooltip="`${item.addressFrom.street}<br/>${item.addressFrom.postalCode} ${item.addressFrom.postalName}`">{{ item.locationNameFrom }}</span>
      </template>

      <template v-else>
        <span v-tooltip="`${item.addressFrom.street}<br/>${item.addressFrom.postalCode} ${item.addressFrom.postalName}`">{{ status.companyName }}</span>
      </template>

    </template>

    <template v-if="item.transactionTypeId === 'HE'">

      <template v-if="item.customerGroupTo === 'X'">
        <span v-tooltip="`${item.addressTo.street}<br/>${item.addressTo.postalCode} ${item.addressTo.postalName}`">{{ item.locationNameTo }}</span>
      </template>

    </template>

  </div>
</template>

<script>

import { mapGetters } from "vuex"
export default {
  props: ["item"],
  computed: {
    ...mapGetters(["status"])
  }
}

</script>