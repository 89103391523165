import api from "./api"

const state = {
  stockDetails: []
}

const getters = {
  stockDetails: state => state.stockDetails
}

const mutations = {
  setStockDetails: (state, stockDetails) => (state.stockDetails = stockDetails)
}

const actions = {
  async getStockDetails({ commit }, locationId) {
    commit("setStockDetails", await api.getStockDetails(locationId))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
