<template>

  <svg fill="none" viewBox="0 0 512 342">

    <template v-if="country === 'fi' || country === 'fi-FI' || country === 'FIN'">
      <path d="M512 .334H0v341.337h512V.334z" fill="#F0F0F0" />
      <path
        d="M512 149.666v42.663H183.652v149.337h-42.674V192.329H0v-42.663h140.978V.329h42.674v149.337H512z"
        fill="#2E52B2"
      />
    </template>

    <template v-else-if="country === 'no' || country === 'nb-NO' || country === 'NOR'">
      <path d="M512 .334H0v341.337h512V.334z" fill="#D80027" />
      <path
        d="M512 210.883H202.195v130.783H122.435V210.883H0V131.111h122.435V.329H202.195v130.782H512V210.883z"
        fill="#F0F0F0"
      />
      <path
        d="M512 149.666v42.663H183.652v149.337h-42.674V192.329H0v-42.663h140.978V.329h42.674v149.337H512z"
        fill="#2E52B2"
      />
    </template>

    <template v-else-if="country === 'dk' || country === 'da-DK' || country === 'DAN' || country === 'DNK'">
      <path d="M512 .333H0V341.67h512V.333z" fill="#D80027" />
      <path d="M192 .33h-64v138.666H0v64h128v138.666h64V202.996h320v-64H192V.33z" fill="#F0F0F0" />
    </template>

    <template v-else-if="country === 'se' || country === 'sv-SE' || country === 'SVE' || country === 'SWE'">
      <path d="M512 .333H0V341.67h512V.333z" fill="#0052B4" />
      <path d="M192 .33h-64v138.666H0v64h128v138.666h64V202.996h320v-64H192V.33z" fill="#FFDA44" />
    </template>

    <template v-else-if="country === 'de' || country === 'de' || country === 'DE' || country === 'DEU'">
      <path fill="#000" d="M0 0h512v114H0z"/>
      <path fill="#D60000" d="M0 114h512v114H0z"/>
      <path fill="#F7C800" d="M0 228h512v114H0z"/>
    </template>

    <template v-else-if="country === 'en' || country === 'en-US' || country === 'ENG'">
      <g clip-path="url(#clip0)">
        <path d="M0 0v342h512V0H0z" fill="#012169"/>
        <path d="M0 0l512 342L0 0zm512 0L0 342z" fill="#000"/>
        <path d="M512 0L0 342M0 0l512 342L0 0z" stroke="#fff" stroke-width="70"/>
        <path d="M0 0l512 342L0 0zm512 0L0 342z" fill="#000"/>
        <path d="M512 0L0 342M0 0l512 342L0 0z" stroke="#C8102E" stroke-width="49"/>
        <path d="M0 171h512z" fill="#000"/>
        <path d="M256 0v342M0 171h512" stroke="#fff" stroke-width="86"/>
        <path d="M0 171h512z" fill="#000"/>
        <path d="M256 0v342M0 171h512" stroke="#C8102E" stroke-width="51"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h512v342H0z"/>
        </clipPath>
      </defs>      
    </template>
  </svg>

</template>

<script>
export default {
  name: "p-flag",
  props: {
    country: {
      type: String,
      required: true
    }
  }
}
</script>
