<template>
  <div class="py-3">{{ summed }}</div>
</template>

<script>

export default {
  props: ["item", "value", "nested"],
  computed: {
    summed () {
      return this.item[this.nested || "transactions"].map(i => i.quantityPicked || i.quantityOrdered).reduce((a, b) => a + b, 0)
    }
  }
}
</script>