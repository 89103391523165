<template>
  <tr v-show="record.visible !== false"  :class="hover ? 'with-hover' : ''" @click="select(record)">
    <td class="p-4" v-for="(header, cellIndex) in cols" :key="cellIndex" :align="header.align">
      <div>
        <template>
          <div v-if="header.type === 'datetime'" class="font-semibold">{{ getDateTime(record, header.value) | dateAndTime }}</div>
          <div v-else-if="header.type === 'date'" class="font-semibold whitespace-no-wrap">{{ getDate(record, header.value) | date }}</div>
          <div v-else-if="header.type === 'check'" class="font-semibold whitespace-no-wrap">            
            <div v-if="record[header.value]" class="rounded-full h-5 w-5 flex items-center justify-center text-white bg-green-500">
              <FontAwesomeIcon :icon="['far', 'check']" />  
            </div>          
          </div>
          <div v-else-if="header.type === 'delete'" class="font-semibold">
            <div v-if="getBoolean(record, header.value) === false">
            <a @click="remove(record)" href="#">
              <font-awesome-icon class="transition transform hover:scale-150 ease-in-out duration-100 text-base" :icon="['far', 'trash-alt']" />
            </a> 
            </div>
          </div> 
          <div v-else class="font-semibold" :class="header.class">{{ getValue(record, header.value) }}</div>
        </template>
      </div>
    </td>
  </tr>
</template>

<script>
import { get } from "lodash"

export default {
  name: "PTableRow",
  props: {
    cols: {
      type: Array,
      required: true
    },
    record: {
      type: Object,
      required: true
    },
    hover: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    select(item) {
      this.$emit("select", item);
    },
    remove (item) {
      this.$emit('delete', item)
    },
    getDateTime (item, path, defaultValue) {
      return get(item, path, defaultValue);
    },
    
    getDate (item, path, defaultValue) {
      return get(item, path, defaultValue);
    },
    getBoolean(item, path) {
      return Boolean(get(item, path, false)) 
    },
    getValue(item, path, defaultValue) {
      const value = get(item, path, defaultValue);
      if (value === true) {
        return "Ja"
      } else if (value === false) {
        return "Nei"
      } else {
        return value
      }

    },
  },
};
</script>

<style scoped>
  tr.with-hover {
    cursor: pointer;
  }

  tr.with-hover:hover {
    background-color: theme('colors.orange.200');        
  }

  td:not(.empty) { 
    border-bottom: 1px solid theme('colors.gray.300');
    border-right: 1px solid theme('colors.gray.300');
  }

  tr.with-hover:hover td {
    color: theme('colors.orange.800');
    border-color: theme('colors.orange.300');    
  }

  tr.with-hover:not(:first-child):hover td {
    background-color: theme('colors.orange.100');
  }
</style>