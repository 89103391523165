<template>
  <div v-if="error" class="mt-1 text-xs text-red-500">
    <font-awesome-icon :icon="['far', 'exclamation-circle']" />
    {{ error }}
  </div>
</template>

<script>
export default {
  name: "p-error",
  props: {    
    error: {
      required: false,
      default: ""
    }
  }
}
</script>