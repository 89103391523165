const routes = [
  {
    path: "delivery",
    component: () =>
      import(
        /* webpackChunkName: "deliveryLayout" */ "@/areas/delivery/layouts/DeliveryLayout.vue"
      ),
    children: [   
      {
        path: "",  
        name: "delivery",
        redirect: { name: "delivery.Delivery" }
      },   
      {
        path: "delivery/:id?",
        name: "delivery.Delivery",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "deliveryDelivery" */ "@/areas/delivery/views/Delivery.vue"
          )
      },   
      {
        path: "external",
        name: "delivery.External",
        component: () =>
          import(
            /* webpackChunkName: "deliveryExternal" */ "@/areas/delivery/views/External.vue"
          )
      }        
    ]
  }
]

export default routes
