const routes = [
  {
    path: "support",
    component: () =>
      import(
        /* webpackChunkName: "supportLayout" */ "@/areas/support/layouts/SupportLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "support",
        redirect: { name: "support.Tickets" }
      },       
      {
        path: "tickets/:id?",
        name: "support.Tickets",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "supportTickets" */ "@/areas/support/views/SupportTickets.vue"
          )
      },    
      {
        path: "settings",
        name: "support.Templates",
        component: () =>
          import(
            /* webpackChunkName: "supportTemplates" */ "@/areas/support/views/SupportTemplates.vue"
          )
      },
    ]
  }
]

export default routes
