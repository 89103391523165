const routes = [
  {
    path: "containers",
    component: () =>
      import(
        /* webpackChunkName: "containersLayout" */ "@/areas/containers/layouts/ContainersLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "containers",        
        redirect: { name: "containers.Rentals" }
      },
      {
        path: "containers/:id?",
        name: "containers.Containers",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "containersContainers" */ "@/areas/containers/views/Containers.vue"
          )
      },
      {
        path: "rentals",
        name: "containers.Rentals",    
        component: () =>
          import(
            /* webpackChunkName: "containersRentals" */ "@/areas/containers/views/ContainerRentals.vue"
          )
      },
      {
        path: "rentals/:id",
        name: "containers.Rental",
        props: true,        
        component: () =>
          import(
            /* webpackChunkName: "containersRental" */ "@/areas/containers/views/ContainerRental.vue"
          )
      },
      {
        path: "orders/:id?",
        name: "containers.Orders",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "containersOrders" */ "@/areas/containers/views/Orders.vue"
          )
      }
    ]
  }
]

export default routes
