<template>
  <PClickOutside :do="() => isOpen = false">
    <div class="relative -mb-1">
      
      <template v-if="isSubmitting">
        <FontAwesomeIcon :icon="['far', 'spinner']" spin />
      </template>

      <button 
      v-else        
        @click="isOpen = !isOpen" 
        :title="$tk('PLanguagePicker.SelectLanguage', true)"
      >
        <PFlag 
          class="w-6 h-4" 
          :country="locale"
        ></PFlag>
      </button>

      <transition 
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">

      <div v-if="isOpen" class="bg-black p-2 absolute z-30 right-0 shadow-xl">
        <button 
          class="p-2 flex items-center group focus:outline-none" 
          v-for="(lang, index) in languages" 
          :key="index" 
          @click="setLocale(lang.locale)"
        >
          <PFlag 
            class="w-6" 
            :country="lang.locale"
          ></PFlag>
          
          <div 
            class="ml-2 group-hover:text-white" 
            :class="{ 'text-white': locale === lang.locale, 'text-gray-400': locale !== lang.locale }"
            v-html="lang.name"
          >
          </div>
        </button>
      </div>

      </transition>
    </div>
  </PClickOutside>
</template>

<script>

import http from "@/http"

export default {
  
  data () {
    return {
      isOpen: false,
      languages: [
        { locale: "nb-NO", name: "Norsk" },
        { locale: "sv-SE", name: "Svenska" },
        { locale: "da-DK", name: "Dansk" },
        { locale: "fi-FI", name: "Soumi" },
        { locale: "en-US", name: "English" },
      ],
      isSubmitting: false
    }    
  },

  computed: {

    locale () {
      return this.$i18n.locale
    }
  },

  methods: {

    async setLocale (locale) {

      this.isSubmitting = true
      
      try {
        
        this.isOpen = false
        
        await http.post("Language", {
          language: locale
        })

        location.reload()

      } catch (error) {

        this.$notifyError(error.reason)

        this.isSubmitting = false
      }
    }
  },
  
  created () {
    const handleEscape = e => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.isOpen = false
      }
    }
    document.addEventListener("keydown", handleEscape)
    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", handleEscape)
    })
  }
  
}
</script>