<template>
  <button
    @click.prevent="$emit('input', !value)"
    :disabled="disabled"
    class="group inline-flex items-center select-none focus:outline-none"
    :class="buttonClassNames"
  >

    <div
      class="border rounded h-4 w-4 relative"
      :class="checkClassNames"
    >
      <FontAwesomeIcon
        v-if="value"
        class="text-xs text-white absolute top-1/2 left-1/2"
        style="transform: translate(-50%,-50%)"
        :icon="['far', 'check']"
      />
    </div>
    <!-- TODO: check all pCheckbox usage, remove v-html if no html needed -->
    <div
      class="ml-2 text-sm text-left"
      v-html="label"
    ></div>

  </button>
</template>

<script>
export default {
  name: "p-checkbox",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }    
  },
  computed: {
    buttonClassNames () {
      return {
        "text-gray-400 cursor-not-allowed": this.disabled        
      }
    },
    checkClassNames () {
      return {
        "border-green-500 bg-green-500": !this.disabled && this.value,
        "border-gray-400": !this.disabled && !this.value,
        "bg-white group-focus:border-white group-focus:shadow-outline": !this.disabled,
        "bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed": this.disabled,
      }
    }   
  }
}
</script>