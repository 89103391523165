import Vue from "vue"
import Vuex from "vuex"
import http from "../http"
import { get, find, filter } from "lodash"
import TokenService from "@/services/TokenService"

Vue.use(Vuex)

// modules
import global from "@/areas/_global/store"
import company from "@/areas/_company/store"
import balance from "@/areas/_balance/store"
import profile from "@/areas/_profile/store"

const state = {
  status: null,
  transactionTypes: [],
  webtaskUsers: [],
  truckTypes: [],
  interCompanies: [],
  notifications: [], // { type: "", text: "", title: "" },
  customerHasLogo: null,
  alerts: [],
  isReady: false,
  hintsVisible: false,
  errors: []
}

const getters = {
  status: state => state.status,
  transactionTypes: state => state.transactionTypes,
  transactionStatuses: state => state.transactionStatuses,
  customer: state => get(state, "status.customer"),
  location: state => get(state, "status.location"),
  user: state => get(state, "status.user"),
  loginUser: state => get(state, "status.loginUser"),
  language: state => get(state, "status.language"),
  customerHasLogo: state => state.customerHasLogo,
  alerts: state => state.alerts,

  isReady: state => state.isReady,
  notifications: state => state.notifications,
  webtaskUsers: state => state.webtaskUsers,
  truckTypes: state => state.truckTypes,
  interCompanies: state => state.interCompanies,
  defaultTruckType: state => find(state.truckTypes, t => t.isDefault),
  defaultTruckTypeId: (state, getters) => getters.defaultTruckType ? getters.defaultTruckType.id : null,

  // hintsVisible: state => state.hintsVisible
  hintsVisible () { return false },
  errors: state => state.errors
}

const mutations = {
  setStatus: (state, status) => state.status = status,
  setStatusUser: (state, user) => state.status.user = user,
  setTransactionTypes: (state, transactionTypes) => state.transactionTypes = transactionTypes,
  setTransactionStatuses: (state, transactionStatuses) => state.transactionStatuses = transactionStatuses,
  removeNotification: (state, notification) => state.notifications = filter(state.notifications, (element) => {
    return element.id !== notification.id
  }),
  setNotification: (state, notification) => state.notifications.unshift(notification),
  setWebtaskUsers: (state, webtaskUsers) => state.webtaskUsers = webtaskUsers,
  setTruckTypes: (state, truckTypes) => state.truckTypes = truckTypes,
  setInterCompanies: (state, interCompanies) => state.interCompanies = interCompanies,
  setCustomerHasLogo: (state, hasLogo) => state.customerHasLogo = hasLogo,
  setCustomerImage: (state, image) => {
    if (state.status && state.status.customer) {
      state.status.customer.image = image
    }
  },
  setAlerts: (state, alerts) => state.alerts = alerts,
  setIsReady: (state, isReady) => state.isReady = isReady,
  setHintsVisible: (state, hintsVisible) => state.hintsVisible = hintsVisible,
  toggleHintsVisible: state => state.hintsVisible = !state.hintsVisible,

  updateLocation (state, location) {
    state.status.location = location
  },
  setErrors (state, errors) {
    state.errors = errors
  }
}

const actions = {
  async init({ commit }) {

    try {

      const [
        status,
        transactionTypes,
        transactionStatuses,
        webtaskUsers,
        truckTypes,
        interCompanies
      ] = await http.post("Actions", 
        [
          "Status", 
          "TransactionTypes", 
          "TransactionStatuses",
          "WebTaskUsers", 
          "TruckTypes", 
          "InterCompany", 
          "Preload"
        ]
      )

      const errors = getErrors([
        status,
        transactionTypes,
        transactionStatuses,
        webtaskUsers,
        truckTypes,
        interCompanies
      ])

      if (errors.length > 0) {
        commit("setErrors", errors)
      } else {      
        commit("setStatus", status)
        commit("setTransactionTypes", transactionTypes)    
        commit("setTransactionStatuses", transactionStatuses)
        commit("setWebtaskUsers", webtaskUsers)
        commit("setTruckTypes", truckTypes)
        commit("setInterCompanies", interCompanies)
        commit("setCustomerHasLogo", get(status, "customer.image") === null ? false : true)
      }
      
    } catch (error) {
      if (error.type === "UnauthorizedAccess") {
        TokenService.removeToken()
        location.href = "/"
      } else {
        commit("setErrors", [{ message: error.message }])        
      }
    }
  },
  
  async updateStatus({ commit }, data) {
    commit("setIsReady", false)
    const status = await http.post("status", data)
    commit("setStatus", status)
  },

  async getWebtaskUsers ({ commit }) {
    const webtaskUsers = await http.get("webtaskUsers")
    commit("setWebtaskUsers", webtaskUsers)
  },

  async updateLocation ({ commit }) {
    const location = await http.get("location")
    commit("updateLocation", location)
  },

  async logout({ commit }) {
    commit("setIsReady", false)
    await http.post("logout")
    TokenService.removeToken()
    commit("setStatus", null)
    location.href = "/"    
  },
  // Supported properties: Title, Text, Type (positive / negative)
  async notify({commit}, data) {
    data.id = Date.now() // Create a unique id for every notification
    commit("setNotification", data)
  },

  async getAlerts ({ commit }) {
    try {
      const alerts = await http.get("Alerts")
      commit("setAlerts", alerts)
    }
    catch (error) {
      this._vm.$appInsights.trackException({ exception: error })
    }
  }
}

const store = new Vuex.Store({
  modules: {
    global,
    company,
    balance,
    profile
  },

  state,
  getters,
  mutations,
  actions
})

const getErrors = function (actions) {
  let errors = []
  actions.forEach(response => {
    if (typeof response === 'object' && !Array.isArray(response) && response !== null && typeof response.error === 'object') {
      errors.push(response.error)
    }
  })
  return errors
}

export default store