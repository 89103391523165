<template>
  <span 
    class="inline-block rounded-full px-4 py-1 font-medium" 
    :class="statusClass"
  >{{ displayText }}</span>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },

  computed: {

    statusClass () {
      return this.status === "Registered" ? "bg-blue-200 text-blue-700" :
             this.status === "Open"       ? "bg-green-100 text-green-700" :
                                            "bg-red-100 text-red-700" 
    },    

    displayText () {
      return this.status === "Registered" ? this.$tk("Common.RentalStatus.Registered") :
             this.status === "Open"       ? this.$tk("Common.RentalStatus.Open")       : 
             this.status === "Closed"     ? this.$tk("Common.RentalStatus.Closed")     : ""
    }
  }  
}
</script>