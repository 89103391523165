const routes = [
  {
    path: "home",
    name: "home",
    // meta: { title: "Forside" },
    component: () =>
      import(/* webpackChunkName: "home" */ "@/areas/home/views/Home.vue")
  }
]

export default routes
