<template>
  <div 
    class="mb-1 text-sm flex" 
    :class="classes" 
    v-if="label"
  >  
    <PTranslation
      v-if="translation"
      :k="translation"
    />

    <div 
      v-else
      v-html="label"
      class="inline-block" 
      @click="$emit('click')"
    ></div>

    <div 
      v-if="required"
      class="ml-1 text-red-500"
    >*</div>

  </div>
</template>

<script>
export default {
  name: "p-label",
  props: {
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      required: false,
      default: "" 
    }  
  },
  computed: {
    
    classes () {
      return {
        "text-red-500": this.error,
        "text-gray-400": this.disabled        
      }
    },

    // If label starts with t: we'll use the PTranslation key with label as key.
    // The PTranslation component will insert a tooltip with field description if
    // the key/label ends with <keyname>_Tooltip

    translation () {
      if (this.label.indexOf("t:") === 0) {
        return this.label.substr(2)
      }
      return ""
    }

    
  }
}
</script>