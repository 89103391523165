<template>
<portal to="dialogs">

    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0">
      <div v-if="visible" class="absolute inset-0 bg-gray-400 bg-opacity-75"></div> <!-- @click="close" -->
    </transition>

    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-55"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-">

      <!-- class="absolute flex flex-col bg-gray-100 m-4 right-0 top-0 bottom-0 z-20 rounded-lg shadow-lg"  -->
        <!-- class="absolute flex flex-col bg-gray-100 max-h-screen z-20 rounded-lg shadow-xl" 
        style="top:50%;left:50%;transform:translate(-50%,-50%)"  -->

      <div 
        v-if="visible" 
        class="absolute flex flex-col bg-gray-100 md:mt-12 m-4 right-0 top-0 bottom-0 z-20 rounded-lg shadow-lg"
        :class="classes"
      >
        
        <header v-if="title" class="flex-grow-0 p-4 border-b bg-white rounded-t-lg border-gray-300">
          <div class="flex items-start justify-between space-x-3">
            <h2 class="text-lg leading-7 font-medium text-gray-900" v-html="title"></h2>
            <div class="h-7 flex items-center">
              <button 
                aria-label="Close panel" 
                class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none" 
                @click="close"
              >
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </header>

        <PLoader v-if="loading" />

        <template v-else>
          <slot />
        </template>

      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  name: "p-dialog",
  props: ["title", "classes", "loading"],
  data () {
    return {
      visible: false
    }
  },
  methods: {
    close () {
      this.$emit("close")
    }
  },
  created () {
    this.$nextTick(() => {
      this.visible = true
    })

    const handleEscape = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.close()
      }
    }
    
    document.addEventListener('keydown', handleEscape)
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', handleEscape)
    })

  }
}
</script>
