<template>
  <img :src="imageSrc" v-if="src" />
</template>

<script>
export default {

  name: "PImage",

  props: {
    src: {
      type: String,
      default: ""
    }
  },

  computed: {
    imageSrc () {
      return `${this.$appConfig.assetsBaseUrl}${this.src}`
    }
  }

}
</script>