<template>
  <div v-if="text" class="mt-1 text-xs text-green-500">
    <font-awesome-icon :icon="['far', 'check-circle']" />
    <span class="ml-1" v-html="text"></span>    
  </div>
</template>

<script>
export default {
  name: "p-valid",
  props: {    
    text: {
      default: ""
    }
  }
}
</script>