<template>  
  <span v-html="html">
  </span>
</template>

<script>

export default {

  props: ["item", "value"],

  computed: {

    html () {
      
      const text = this.item[this.value].containerTransactionTypeName
      const date = this.item[this.value].createdLocal

      if (text && date) {
        return `${this.$root.$options.filters.date(date)} <span class="font-semibold">${text}</span>`
      }

      return ""
    }
  }
}
</script>