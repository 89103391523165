<template>
  <div class="relative" v-if="data">
                    
    <button class="flex items-center justify-center absolute inset-0 w-full bg-transparent z-10 group focus:outline-none" @click="isZoomed = true">
      <div class="bg-green-100 opacity-50 rounded-full h-12 w-12 flex items-center justify-center group-focus:bg-white group-hover:bg-white">
        <div class="text-xl leading-none">
          <font-awesome-icon :icon="['far', 'search']" />
        </div>
      </div>
    </button>

    <img :src="`data:image/jpeg;base64, ${data}`" alt="" />    

    <portal to="modals">      
      <div class="fixed inset-0 p-10 flex justify-center items-center bg-black bg-opacity-75 z-30" v-if="isZoomed">
        <button                        
          class="absolute flex items-center justify-center w-10 h-10 rounded-full shadow-xl bg-gray-200 text-gray-900 top-10 right-10 z-30 hover:bg-white focus:bg-white focus:outline-none" 
          @click="isZoomed = null"
          @keypress.esc.prevent.stop="isZoomed = false"
        >
          <font-awesome-icon :icon="['far', 'times']" />
        </button>
        <img class="select-none shadow-lg" :src="`data:image/jpeg;base64, ${data}`" alt="" />
      </div>
    </portal>

  </div>
</template>

<script>
export default {
  
  name: "p-thumbnail-image",
  
  props: {
    data: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    }
  },

  data () {
    return {
      isZoomed: false
    }
  }

}
</script>