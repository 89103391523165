<template>
  <div v-if="text" class="mt-1 text-xs text-yellow-500">
    <font-awesome-icon :icon="['fas', 'question-circle']" />
    <span class="ml-1" v-html="text"></span>
  </div>
</template>

<script>
export default {
  name: "p-info",
  props: {    
    text: {
      default: ""
    }
  }
}
</script>