<template>
  <FontAwesomeIcon 
    tabindex="-1"
    class="cursor-pointer focus:outline-none"
    :class="{
      'text-white': color === 'white',
      'text-black': color === 'black',
      'text-green-500 hover:text-black': color === 'green',
      'text-green-500 hover:text-black': !color
    }"
    v-if="content"
    v-tooltip="{ 
      content: content, 
      delay: { 
        show: 100, 
        hide: 100 
      } 
    }"
    :icon="['fas', 'question-circle']" 
  />
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      validator: function (value) {
        return !value || (['white', 'green', 'black'].indexOf(value) >= 0)
      }
    }
  }
}
</script>