<template> 
<div> 
  
  <button 
    @click.prevent="$emit('input', !value)" 
    :disabled="disabled" 
    class="group inline-flex items-center select-none focus:outline-none" 
    :class="buttonClassNames"
  >    
    <FontAwesomeIcon 
      :icon="['far', value ? 'check-circle' : 'circle']" 
      class="text-base" 
      :class="iconClassNames"
    /> 

    <div 
      class="ml-2 text-sm" 
      v-if="label"
      v-html="label"
    >
    </div>

  </button>
  </div>
</template>

<script>
export default {
  name: "p-radio",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }    
  },
  computed: {
    buttonClassNames () {
      return {
        "text-gray-400 cursor-not-allowed": this.disabled,
        "": !this.disabled && this.value
      }
    },
    iconClassNames () {
      return {
        "group-hover:text-green-500 group-focus:text-green-500": !this.disabled && !this.value,
        "text-green-500": !this.disabled && this.value
      }
    }
  }
}
</script>