<template>
  <div v-if="item">
    <div class="text-red-500 float-right" v-if="item.statusId === 2">
      {{ $t("Common.General.Blocked")}}
    </div>
    {{ item.name }} ({{ item.id }})
  </div>
</template>

<script>
export default {
  props: ["item"]
}
</script>