<template>
  <div class="flex items-center py-3">
    <div class="flex-shrink-0">
      <img :src="`${assetsBaseUrl}/media.ashx?${item.productImage}&template=product-icon`" />
    </div>
    <div class="ml-2">
      {{ item.name }}
      <!-- 
      <span class="font-medium">{{ item.productName }}</span>
      <span v-if="showPoolingQuality">, {{ item.qualityName }}</span>
      <span v-if="showTreatment">, {{ item.treatmentName }}</span>             
      -->
    </div>
  </div>
</template>

<script>
// import { get } from "lodash"
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: false
    },
    componentConfig: {
      type: Object,
      required: false
    }
  },
  computed: {
    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl
    },
    // showPoolingQuality () {
    //   return get(this.config, "showPoolingQuality", true) || !this.item.isPooling
    // },
    // showTreatment () {
    //   return get(this.componentConfig, "showTreatment", false) && get(this.item, "treatmentName") !== "Normal";
    // }
  }
}
</script>