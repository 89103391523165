<template>
  <div class="flex-shrink-0">

    <!-- desktop -->
    <div class="justify-between flex-shrink-0 px-3 h-20 shadow-inner hidden md:flex">
      <div class="flex items-center">
        <portal-target name="header">
          <div class="flex items-center">
            <router-link
              class="ml-1 mr-3 py-2 text-sm leading-none font-semibold area-link flex items-center"
              :class="{ 'ml-2': index > 0 }"
              v-for="(link, index) in links"
              :key="index"
              :to="{ name: link.route }"            
              :active-class="link.highlight !== false ? 'area-link-active' : ''"
            >
            <FontAwesomeIcon v-if="link.icon" :icon="['far', link.icon]" />
            <div :class="{ 'ml-2': link.icon }" v-html="link.name"></div>
            </router-link>
          </div>
        </portal-target>
      </div>
      <div class="mr-1 flex items-center space-x-4 flex-wrap justify-end gap-1">
        <portal-target name="filters"></portal-target>      
        <portal-target name="actions"></portal-target>
      </div>
    </div>

    <!-- mobile -->
    <div class="py-5 px-4 md:hidden">

      <PSelect      
        :items="links"
        :disabled="links.length <= 1"
        itemText="name"
        itemValue="route"                
        v-model="selectedRoute">
      </PSelect>
      
      <div v-if="hasFilters || hasActions" v-click-outside="closeFiltersAndActions">
        <div class="flex items-center justify-between space-x-2 mt-5">        
          <PButton v-if="hasFilters" @click="toggleFilters()" icon="filter" :color="filtersVisible ? 'primary' : 'secondary'">
            <PTranslation k="Common.General.Selection" />
          </PButton>
          <PButton v-if="hasActions" @click="toggleActions()" icon="arrow-down" :color="actionsVisible ? 'primary' : 'secondary'">
            <PTranslation k="Common.General.Actions" />
          </PButton>        
        </div>

        <div class="relative" v-if="filtersVisible || actionsVisible">        
          <div class="absolute top-2 w-full z-10 bg-white border border-gray-400 p-6 shadow-md rounded-md">          
            <portal-target v-if="filtersVisible" name="filters"></portal-target>      
            <portal-target v-if="actionsVisible" name="actions"></portal-target>
          </div>      
        </div>      
      
      </div>

    </div>
    
  </div>
</template>

<script>
import { Wormhole } from "portal-vue"
export default {
  name: "AreaNav",
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedRoute: "",
      filtersVisible: false,
      actionsVisible: false
    }
  },
  computed: {
    hasFilters () {
      return Wormhole.hasContentFor("filters")
    },
    hasActions () {
      return Wormhole.hasContentFor("actions")
    }
  },
  methods: {
    toggleFilters () {
      this.actionsVisible = false
      this.filtersVisible = !this.filtersVisible
    },
    toggleActions () {
      this.filtersVisible = false
      this.actionsVisible = !this.actionsVisible
    },
    closeFiltersAndActions () {
      if (this.filtersVisible || this.actionsVisible) {
        this.filtersVisible = false
        this.actionsVisible = false
      }
    }
  },
  mounted () {    
    if (this.links.length > 0) {
      const index = this.links.findIndex(x => x.route === this.$route.name)
      if (index >= 0) {
      this.selectedRoute = this.links[index].route
      }
    }
  },
  watch: {
    selectedRoute: function (val) {            
      if (val && this.$router.currentRoute.name !== val) {
        this.$router.push({ name: val })
      }
    }
  }
}
</script>

<style>
.area-link.area-link-active {
  @apply text-green-500 border-b-2 border-green-500;
}

.area-link:not(.area-link-active) {
  @apply text-gray-500 border-b-2 border-transparent;
}

.area-link:not(.area-link-active):hover {
  @apply text-gray-500  border-gray-300;
}

</style>
