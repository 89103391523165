const routes = [
  {
    path: "company",
    component: () =>
      import(
        /* webpackChunkName: "deliveryLayout" */ "@/areas/_company/layouts/CompanyLayout.vue"
      ),
    children: [
      {
        path: "index",
        name: "company",
        component: () =>
          import(
            /* webpackChunkName: "companyLocationList" */ "@/areas/_company/views/Company.vue"
          )
      },
      {
        path: "users",
        name: "companyUserList",
        component: () =>
          import(
            /* webpackChunkName: "companyUserList" */ "@/areas/_company/views/UserList.vue"
          )
      },
      {
        path: "locations",
        name: "companyLocationList",
        component: () =>
          import(
            /* webpackChunkName: "companyLocationList" */ "@/areas/_company/views/LocationList.vue"
          )
      },
      {
        path: "external",
        name: "companyExternalLocations",
        component: () =>
          import(
            /* webpackChunkName: "companyExternalLocations" */ "@/areas/_company/views/ExternalLocations.vue"
          )
      },
      {
        path: "audit",
        name: "companyAudit",
        component: () =>
          import(
            /* webpackChunkName: "companyAudit" */ "@/areas/_company/views/Audit.vue"
          )
      },
      {
        path: "email-archive",
        name: "companyEmailArchive",
        component: () =>
          import(
            /* webpackChunkName: "companyEmailArchive" */ "@/areas/_company/views/EmailArchive.vue"
          )
      }
    ]
  }
]

export default routes
