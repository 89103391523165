
const routes = [
  {
    path: "/pallet-receipts",
    component: () =>
      import(
        /* webpackChunkName: "palletReceiptsLayout" */ "@/apps/pallet-receipts/layouts/PalletReceiptsLayout.vue"
      ),
    children: [
      {
        path: ":id?",
        name: "palletReceipts",        
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "palletReceipts" */ "@/apps/pallet-receipts/views/PalletReceipts.vue"
          )
      },
      {
        path: "log",
        name: "palletReceiptsLog",        
        component: () =>
          import(
            /* webpackChunkName: "palletReceiptsLog" */ "@/apps/pallet-receipts/views/PalletReceiptsLog.vue"
          )
      },
    ]
  },
  {
    path: "/pallet-receipts-inv",    
    props: {
      inv: true
    },
    component: () =>
      import(
        /* webpackChunkName: "palletReceiptsLayout" */ "@/apps/pallet-receipts/layouts/PalletReceiptsLayout.vue"
      ),
    children: [
      {
        path: ":id?",
        name: "palletReceiptsInv",        
        props: {
          default: true,
          inv: true
        },
        component: () =>
          import(
            /* webpackChunkName: "palletReceipts" */ "@/apps/pallet-receipts/views/PalletReceipts.vue"
          )
      },
      {
        path: "log",
        name: "palletReceiptsLogInv",        
        props: {
          inv: true
        },
        component: () =>
          import(
            /* webpackChunkName: "palletReceiptsLog" */ "@/apps/pallet-receipts/views/PalletReceiptsLog.vue"
          )
      },
    ]
  }
]

export default routes
