const routes = [
  {
    path: "global",
    component: () =>
      import(
        /* webpackChunkName: "globalLayout" */ "@/areas/_global/layouts/GlobalLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "global",
        redirect: { name: "global.Publishing" }
      },
      {
        path: "publishing",
        name: "global.Publishing",
        component: () =>
          import(
            /* webpackChunkName: "globalPublishing" */ "@/areas/_global/views/GlobalPublishing.vue"
          )
      },
      {
        path: "caching",
        name: "global.Caching",
        component: () =>
          import(
            /* webpackChunkName: "globalCaching" */ "@/areas/_global/views/GlobalCaching.vue"
          )
      },
      {
        path: "log/:id?",
        name: "global.Log",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "globalLog" */ "@/areas/_global/views/Log.vue"
          )
      }
    ]
  }
]

export default routes
