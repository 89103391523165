<template>
  <div class="flex space-x-2 items-center">
    <slot />
  </div>
</template>

<script>
  export default {
    name: "PButtonGroup"
  }
</script>