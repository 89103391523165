<template>
  <div>
    <PLabel
      :label="label"
      :error="error"
      @click="$refs.input.focus()"
    />
    <div class="relative group">
      <FontAwesomeIcon
        class="text-yellow-500 absolute top-1/2 ml-2 text-sm"
        :icon="['far', 'exclamation-triangle']"
        v-if="warnActive"
        v-tooltip="{
          content: warnMessage,
          delay: {
            show: 100,
            hide: 100,
          },
        }"
      />
      <input
        ref="input"
        class="border rounded shadow-inner leading-none w-full focus:outline-none"
        :class="classNames"
        :data-extra="extra"
        :data-max="max"
        :data-min="min"
        :data-step="step"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :tabindex="tabIndex"
        :value="value"
        @focus="(event) => event.target.select()"
        @input="onInput"
        @keydown.down="decrease"
        @keydown.up="increase"
        @keydown="onKeyDown"
      />
      <button
        tabindex="-1"
        :disabled="disabled || value >= max"
        :class="{
          'cursor-not-allowed text-gray-400 border-gray-300':
            disabled || max === 0 || value === max,
          'text-gray-500 border-gray-400 hover:text-gray-900 group-focus:border-gray-500':
            !disabled && max !== 0 && value < max,
        }"
        @click.prevent="increase"
        v-if="!readonly"
        class="absolute flex items-center justify-center h-1/2 w-6 border-l border-b focus:outline-none right-0 top-0"
      >
        <FontAwesomeIcon
          class="text-xs"
          :icon="['far', 'chevron-up']"
        />
      </button>
      <button
        tabindex="-1"
        :disabled="disabled || value === min"
        :class="{
          'cursor-not-allowed text-gray-400 border-gray-300':
            disabled || max === 0 || value === min,
          'text-gray-500 border-gray-400 hover:text-gray-900 group-focus:border-gray-500':
            !disabled && max !== 0 && value > min,
        }"
        @click.prevent="decrease"
        v-if="!readonly"
        class="absolute flex items-center justify-center h-1/2 w-6 border-l focus:outline-none right-0 bottom-0"
      >
        <FontAwesomeIcon
          class="text-xs"
          :icon="['far', 'chevron-down']"
        />
      </button>
    </div>
    <PError :error="error" />
  </div>
</template>

<script>
import PLabel from "./partials/PLabel";
import PError from "./partials/PError";
import debounce from "lodash/debounce";

const inputCaptured = function(ev) {
  var input = ev.srcElement.value;
  if (input === "") {
    input = "0";
  }
  var num = parseInt(input);
  var step = parseInt(ev.srcElement.getAttribute("data-step"));
  var extra = parseInt(ev.srcElement.getAttribute("data-extra"));
  var maxAttr = ev.srcElement.getAttribute("data-max");

  if (maxAttr !== "Infinity") {
    var max = parseInt(maxAttr, 10) - extra;
    if (num > max) {
      num = max;
    }
  }

  if (num !== 0) {
    num = parseInt((num - extra) / step, 10) * step + extra;
  }

  ev.srcElement.value = num;
  this.$emit("input", num);
};

const isNaN = Number.isNaN || window.isNaN;

export default {
  name: "p-number",
  components: {
    PLabel,
    PError,
  },
  props: {
    value: { type: Number, default: 0 },
    extra: { type: Number, default: 0 },
    label: { type: String, default: "" },
    max: { type: Number, default: Infinity },
    min: { type: Number, default: 0 },
    warn: { type: Number, default: 0 },
    warnBelow: { type: Number, default: 0 },
    warnMessage: { type: String, default: "" },
    step: { type: Number, default: 1 },
    placeholder: { type: String, default: "" },
    size: {
      type: String,
      default: "normal",
      validator: function(value) {
        return ["small", "normal"].indexOf(value) !== -1;
      },
    },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    error: { type: String, default: "" },
    wait: { type: Number, default: 0 },
    focus: { type: Boolean, default: false },
  },
  computed: {
    tabIndex() {
      return this.disabled || this.readonly ? "-1" : "";
    },

    increasable() {
      const num = this.value;
      return isNaN(num) || num + this.step <= this.max;
    },

    decreasable() {
      const num = this.value;
      return isNaN(num) || num - this.step >= this.min;
    },

    warnActive() {
      return (
        this.value > 0 &&
        ((this.warn > 0 && this.value >= this.warn) ||
          (this.warnBelow > 0 && this.value <= this.warnBelow))
      );
    },

    classNames() {
      return {
        "bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed":
          this.disabled,
        "border-gray-400 focus:border-white focus:shadow-outline":
          !this.error && !this.disabled && !this.readonly,
        "border-red-500 focus:border-red-600 placeholder-red-300 text-red-600":
          this.error,
        "p-2 h-10": this.size === "normal",
        "p-1 h-8 text-xs": this.size === "small",
        "text-right text-gray-500": this.readonly,
        "text-right pr-8": !this.readonly,
      };
    },

    onInput() {
      return debounce(inputCaptured, this.wait).bind(this);
    },
  },
  methods: {
    onKeyDown(event) {
      const code = event.keyCode;
      var isValid =
        (code >= 48 && code <= 57) || // 0-9
        (code >= 96 && code <= 105) || // 0-9 numpad
        (code >= 37 && code <= 40) || // arrows
        code === 9 || // tab
        code === 8 || // backspace
        code === 46 || // delete
        (code === 109 && this.min < 0) || // minus (numpad)
        (code === 189 && this.min < 0); // minus
      if (!isValid) {
        event.preventDefault();
      }
    },

    increase() {
      if (!this.increasable) return;
      if (isNaN(this.value)) {
        this.$emit("input", 0);
      }
      this.$emit("input", parseInt(this.value, 10) + this.step);
    },

    decrease() {
      if (!this.decreasable) return;
      if (this.value > this.min) {
        this.$emit("input", parseInt(this.value, 10) - this.step);
      }
    },
  },

  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  },
};
</script>
