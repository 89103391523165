<template>
  <div class="py-1">
    
    <span 
      class="inline-block rounded-full px-4 font-medium py-1" 
      :class="statusClass"
      v-html="displayText"
    ></span>
    
    <div 
      class="mt-1 text-gray-500" 
      v-if="status === 'NotAgreed' && item.isReceiverRegistered"
      v-html="$tk('Common.OrderStatus.RecipientRegistered')"
    ></div>

  </div>
</template>

<script>

import { getStatusText } from "@/helpers"

export default {

  props: ["item"],

  computed: {

    status () {
      return this.item.transactionStatus
    },
    
    statusClass () {
      return this.status === "Web"           ? "bg-blue-500 text-blue-100" :
             this.status === "Ordered"       ? "xbg-blue-200 text-blue-500" :
             this.status === "Sent"          ? "xbg-green-200 text-green-600" :             
             this.status === "NotAgreed"     ? "bg-red-500 text-red-100" :
             this.status === "Confirmed"     ? "xbg-gray-200 text-gray-500" :
             this.status === "Returnable"    ? "xbg-yellow-200 text-yellow-700" :
                                               "xbg-gray-200 text-gray-500"
    },    

    displayText () {
      return getStatusText(this.status, this)      
    }
  }  
}
</script>