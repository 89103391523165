<template>
  <table class="w-full border-collapse" cellspacing="0" cellpadding="0" border="0">
    <thead>
      <tr>
        <th 
          class="bg-gradient-to-b from-gray-500 to-gray-600" 
          width="30" 
          v-if="selectable"
        ></th>
        <th 
          v-for="(col, index) in allCols" 
          :key="index"
          :width="col.width"
          :align="col.align"   
          class="leading-4 select-none font-medium text-white"
          :class="{ 
            'bg-gradient-to-b hover:from-gray-400 hover:to-gray-500 cursor-pointer': col.sortable, 
            'bg-gradient-to-b from-gray-400 to-gray-500': col.value === orderByField, 
            'bg-gradient-to-b from-gray-500 to-gray-600': col.value !== orderByField 
            }"
          @click="$emit('sort', col)"            
        >
          <div class="p-4" :class="{ 'flex justify-between': col.sortable }">
            <div v-html="col.text"></div>
            <FontAwesomeIcon 
              class="ml-2"
              v-if="col.sortable"              
              :icon="[
                'far',
                orderByField === col.value
                  ? orderByDescending
                    ? 'sort-down'
                    : 'sort-up'
                  : 'sort'
              ]"
            />
          </div>
        </th>
        <th class="bg-gradient-to-b from-gray-500 to-gray-600" width="30" v-if="expandable"></th>
      </tr>
    </thead>

    <POrderGridRow
      v-for="(order, index) in orders" 
      :nested="nested" 
      :cols="cols"      
      :order="order"
      :index="index"        
      :key="index"
      :expandable="expandable"
      :expanded="expandAll"
      :selectable="selectable"
      :selected="isSelected(order)"
      @select="$emit('select', order)"
      @click="$emit('click', order, $event)"
    >
    </POrderGridRow>

    <tfoot v-if="showFooter">
      <tr>
        <td class="sticky bottom-0" :colspan="allCols.length + 1">
          <div class="bg-white  px-4 py-2 border-t border-gray-300 'text-gray-800">
            <div class="flex items-center" v-if="loading">
              <FontAwesomeIcon :icon="['far', 'spinner']" pulse />
              <div class="ml-2">
                <PTranslation k="Common.General.PleaseWait" />                
              </div>
            </div>
            <template v-else>
              {{ $t("POrderGridTable.ShowingXofYOrders", { count: orders.length, total: totalCount }) }}              
            </template>
          </div>
        </td>
      </tr>
    </tfoot>

  </table>
</template>

<script>
import POrderGridRow from "./POrderGridRow"

import { 
  forEach, 
  findIndex
} from "lodash"

export default {
  name: "POrderGridTable",
  components: {
    POrderGridRow
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    orders: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    orderByField: {
      type: String,
      default: ""
    },
    orderByDescending: {
      type: Boolean,
      default: false    
    },
    totalCount: {
      type: Number,
      default: 0
    },
    nested: {
      type: String,
      default: "transactions"
    },
    expandable: {
      type: Boolean,
      default: true
    },
    expandAll: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    allCols () {
      let arr = []
      forEach(this.cols, col => {
        if (col.nested) {
          arr = arr.concat(col.nested)
        } else {
          arr.push(col)
        }
      })      
      return arr
    }
  },

  methods: {
    isSelected (order) {
      return findIndex(this.value, value => value === order.id) >= 0
    },
    onSelect (order) {
      this.$emit("select", order)      
    }
  }
}
</script>

<style scoped>

  table {
    border-spacing: 0;
    border: 0;    
    font-size: theme('fontSize.xs');
    color: theme('colors.gray.700');
  }

  th { 
    top: 0;
    margin: 0; 
    position: sticky;
    padding: 0;
    line-height: theme('lineHeight.none');    
    border-right: 1px solid theme('colors.gray.600');
    box-shadow: 1px 0 0 theme('colors.gray.600'); 
  }

</style>