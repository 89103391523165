import { some } from "lodash"

export const getStatusText = function (status, instance) { // , lang

  //  portal 0 Web
  //  portal 1 Ordered
  //         2 ConfirmedWaba
  //         3 Picked
  //  portal 4 Sent
  //  portal 5 Confirmed
  //  portal 6 NotAgreed
  //         7 CancelledWaba
  //         8 Replaced
  //         9 WaitForSort
  //        10 Treatment
  //        11 WaitForConfirmation
  //        12 ReceivedSort
  //        13 WaitForAccept
  //        14 WaitForAgreement

  return status === "Web"                 ? instance.$tk("Common.OrderStatus.Draft") :
         status === "Ordered"             ? instance.$tk("Common.OrderStatus.Ordered") :
         status === "ConfirmedWaba"       ? instance.$tk("Common.OrderStatus.ConfirmedWaba") : 
         status === "Picked"              ? instance.$tk("Common.OrderStatus.Picked") : 
         status === "Sent"                ? instance.$tk("Common.OrderStatus.Sent") :    
         status === "Confirmed"           ? instance.$tk("Common.OrderStatus.Confirmed") :
         status === "NotAgreed"           ? instance.$tk("Common.OrderStatus.NotAgreed") :
         status === "CancelledWaba"       ? instance.$tk("Common.OrderStatus.CancelledWaba") :
         status === "Replaced"            ? instance.$tk("Common.OrderStatus.Replaced") :
         status === "WaitForSort"         ? instance.$tk("Common.OrderStatus.WaitForSort") :
         status === "Treatment"           ? instance.$tk("Common.OrderStatus.Treatment") :
         status === "WaitForConfirmation" ? instance.$tk("Common.OrderStatus.WaitForConfirmation") :
         status === "ReceivedSort"        ? instance.$tk("Common.OrderStatus.ReceivedSort") :
         status === "WaitForAccept"       ? instance.$tk("Common.OrderStatus.WaitForAccept") :
         status === "WaitForAgreement"    ? instance.$tk("Common.OrderStatus.WaitForAgreement") :
         status

}

export const nest = (items, id = null, link = "parentId") =>
  items
  .filter(item => item[link] === id)
  .map(item => ({
    ...item,
    children: nest(items, item.id) 
  }))

  export const getRoute = function (order, location) {

    const status = order.transactionStatusId
    const type = order.transactionTypeId
    const isIncoming = order.locationIdTo === location.id    
    const inTransit = some([2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14], id => id === status)
    
    let name

    if (inTransit) {
      name = isIncoming ? "reception.Reception" : "expedition.Expedition"
    } else {
      if (type === "TO") {
        name = isIncoming ? "orders.Orders" : "expedition.Expedition"
      }
      else if (type === "HE") {
        name = isIncoming ? "expedition.Expedition" : "dispatch.Dispatch"
      }
      else {
        name = "delivery.Delivery"
      }
    }
    
    return { 
      name, 
      params: { 
        id: order.id 
      }
    }

  }

  /**
 * This handler retrieves the images from the clipboard as a blob and returns it in a callback.
 * 
 * @param pasteEvent 
 * @param callback 
 */
export const retrieveImageFromClipboardAsBlob = function (pasteEvent, callback){

	if(pasteEvent.clipboardData == false){
        if(typeof(callback) == "function"){
            callback(undefined);
        }
    }

    var items = pasteEvent.clipboardData.items;

    if(items == undefined){
        if(typeof(callback) == "function"){
            callback(undefined);
        }
    }

    for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();

        if(typeof(callback) == "function"){
            callback(blob);
        }
    }
}

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
