const routes = [
  {
    path: "expedition",
    component: () =>
      import(
        /* webpackChunkName: "expeditionLayout" */ "@/areas/expedition/layouts/ExpeditionLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "expedition",
        redirect: { name: "expedition.Expedition" }
      },
      {
        path: "expedition/:id?",
        name: "expedition.Expedition",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "expeditionExpedition" */ "@/areas/expedition/views/Expedition.vue"
          )
      },            
      {
        path: "direct",
        name: "expedition.Direct",
        component: () =>
          import(
            /* webpackChunkName: "expeditionDirect" */ "@/areas/expedition/views/Direct.vue"
          )
      }
    ]
  }
]

export default routes
