<template>
  <router-view />
</template>

<script>
export default {
  name: "Portal",
  /*
  errorCaptured(err) {
    this.$notifyError(
      err.toString()
    )
  }*/
}
</script>
