<template>
  <div class="py-3" :class="classes">    
    {{ item.loadText }}
  </div>
</template>

<script>
export default {
  props: ['item', 'classes'],
  computed: {
    loadType () {
      return this.$store.getters.location.loadType
    }
  }
}
</script>
