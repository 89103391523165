<template>
  <div
    v-tooltip="{ 
      content: tooltipContent, 
      delay: { 
        show: 100, 
        hide: 100 
      }
    }"
  >
    <template v-if="customsHandler === 'kgh'">KGH</template>
    
    <template v-else-if="customsHandler !== 'none'">

      <div class="rounded-full flex items-center justify-center py-1 h-7 w-7 text-base text-white"
        :class="{          
          'bg-red-500': customsStatus === '',
          'bg-orange-500': customsStatus === 'pending',
          'bg-green-500': customsStatus === 'done'
        }"
      > 
        <FontAwesomeIcon :icon="['far', 'stamp']" />  
      </div>

    </template>
  </div>
</template>

<script>

import { get } from "lodash"

export default {

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    
    customsHandler () {
      return get(this.item, "customsHandler", "")
    },
    
    customsStatus () {
      return get(this.item, "customsStatus", "")
    },

    tooltipContent () {
      return this.customsHandler === 'kgh' ? this.$t("PDisplayCustomsClearance.KGH") :
             this.customsHandler === 'self' ? this.$t("PDisplayCustomsClearance.SelfDeclared") :             
             this.customsHandler === 'required' ? this.$t("PDisplayCustomsClearance.RequiresDeclaration") : ""
    }

  }
}
</script>

