<template>
  <div>
    
    <div 
      class="mb-1 text-sm" 
      v-if="label" 
      v-html="label"
    ></div>

    <div class="flex">
      <button 
        class="w-1/5 flex items-center justify-center transition duration-200 h-10 border-t border-b border-l focus:outline-none"
        v-for="(weekday, index) in weekdays"
        ref="weekday"
        :disabled="disabled"        
        :key="index"
        :class="{
          'bg-white border-gray-400': !weekday.value && !disabled,
          'bg-gray-50 border-gray-300 text-gray-400': !weekday.value && disabled,
          'cursor-not-allowed': disabled,
          'rounded-l-full border-l': index === 0,
          'rounded-r-full border-r': index === weekdays.length - 1,
          'bg-green-200 text-green-800 border-green-400': weekday.value && !disabled,
          'bg-green-100 text-green-500 border-green-300': weekday.value && disabled,
          'focus:border-gray-500 hover:border-gray-500': !weekday.value && !disabled,
          'focus:border-green-500 hover:border-green-500': weekday.value && !disabled,
        }"
        @click.prevent="onToggle(weekday)"
      >
        <!-- <font-awesome-icon :icon="['far', weekday.value ? 'check-square': 'square' ]" /> -->
        <div class="ml-2 text-sm">
          {{ weekday.label }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { get } from "lodash"

export default {
  name: "p-weekday-picker",

  props: {
    value: Object,
    label: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      weekdays: [
        { label: this.$tk("Common.Days.Mo"), value: false },
        { label: this.$tk("Common.Days.Tu"), value: false },
        { label: this.$tk("Common.Days.We"), value: false },
        { label: this.$tk("Common.Days.Th"), value: false },
        { label: this.$tk("Common.Days.Fr"), value: false }
      ]
    }
  },

  methods: {
    onToggle (weekday) {
      weekday.value = !weekday.value

      this.$emit("input", {
        monday: this.weekdays[0].value,
        tuesday: this.weekdays[1].value,
        wednesday: this.weekdays[2].value,
        thursday: this.weekdays[3].value,
        friday: this.weekdays[4].value,
      })
    }
  },

  mounted () {
    if (this.focus) {
      this.$refs.weekday[0].focus()
    }
  },

  watch: {
    value: {
      handler: function (val) {
        this.weekdays[0].value = get(val, "monday", false)
        this.weekdays[1].value = get(val, "tuesday", false)
        this.weekdays[2].value = get(val, "wednesday", false)
        this.weekdays[3].value = get(val, "thursday", false)
        this.weekdays[4].value = get(val, "friday", false)
      },
      immediate: true
    }
  }
}
</script>

