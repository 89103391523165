/*
 * This plugin is installed on the instance and the methods can be used through 
 * this.$ syntax. It will display a message on the top left of the screen 
 * for x number of seconds
 */

import store from "@/store"

function install (Vue) { 
  Vue.prototype.$notifySuccess = function (title, text) {
    store.dispatch("notify", {
      title: title,
      text: text,
      type: "positive"
    }) 
  }
  
 Vue.prototype.$notifyError = function (title, text) {
    store.dispatch("notify", {
      title: title,
      text: text,
      type: "negative"
    }) 
  }
}

export default install
