<template>

  <div>

    <PClickOutside :do="() => isOpen = false">

      <div class="relative">
        <button
          @click="isOpen = !isOpen"
          class="relative block focus:outline-none"
          :class="classes"
        >
          <FontAwesomeIcon 
            :icon="['far', 'bars']" 
            class="text-2xl" 
          />

        </button>

        <transition 
          enter-active-class="transition ease-out duration-100"
          enter-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95">

        <div
          v-if="isOpen"
          class="absolute z-30 right-0 mt-1 w-48 bg-white rounded border border-gray-300 shadow-lg"
        >

          <div class="pb-2 mb-2 border-b border-gray-300" v-if="mobileLinks">

            <router-link
              :to="{ name: link.route }"              
              v-for="(link, index) in mobileLinks"
              :key="index"
              @click.native="isOpen = false"
              active-class="mobile-app-link-active"
              class="flex items-center px-4 py-2 text-green-800 hover:bg-green-100 text-sm"
            >
            
              <div class="w-8">
                <FontAwesomeIcon 
                  :icon="['far', link.icon]" 
                  v-if="link.icon" 
                  size="lg" 
                />
              </div>

              <div class="inline-block relative">
                <span v-html="link.name"></span>
                <div v-if="link.badge" class="badge">{{ link.badge }}</div>
              </div>

            </router-link>
          </div>

          <div class="pb-2 mb-2 border-b border-gray-300 md:hidden" v-if="user">
            <a href 
              @click.prevent="onUserClick()" 
              class="block px-4 py-2 text-green-800 hover:bg-green-100 text-sm">
              {{ user.name }}
            </a> 
          </div>           


          <a 
            href 
            class="block px-4 py-2 text-green-800 hover:bg-green-100 text-sm"
            @click.prevent="onMyProfile"
            v-html="$tk('MenuDropdown.MyProfile')"
          ></a>

          <router-link
            :to="{ name: link.route }"
            v-html="link.name"
            v-for="(link, index) in links"
            :key="index"
            @click.native="isOpen = false"
            class="block px-4 py-2 text-green-800 hover:bg-green-100 text-sm"
          ></router-link>

          <div class="py-2 mt-2 border-t border-gray-300">

            <a href
              class="block px-4 py-2 text-green-800 hover:bg-green-100 text-sm"
              @click.prevent="onLogout"
              v-html="$tk('PUserContext.Logout')"
            ></a>
          
          </div>

        </div>

        </transition>
        
      </div>

    </PClickOutside>

    <ProfileDialog 
      v-if="user && showProfileVisible" 
      :userId="user.id" 
      :closeOnEsc="true" 
      @close="showProfileVisible = false"
    />

  </div>

</template>

<script>

import { mapGetters } from "vuex"
import ProfileDialog from "@/areas/_profile/dialogs/ProfileDialog"

export default {
  
  components: {
    ProfileDialog
  },

  props: ["invert", "mobileLinks"],

  data () {
    return {
      isOpen: false,
      links: [],
      showProfileVisible: false
    }
  },

  computed: {
    ...mapGetters(["user", "location", "customer", "loginUser"]),
    
    classes () {
      return {
        'text-white hover:text-green-500 focus:text-green-500': this.invert,
        'text-green-500 hover:text-black focus:text-black': !this.invert
      }
    }
  },

  methods: {

    init () {

      this.links = []

      if (this.user.isCustomerAdmin) {
        this.links.push({ 
          route: "company", 
          name: this.$tk("MenuDropdown.MyCompany") 
        })
      }

      if (this.loginUser.isGlobalAdmin) {        
        this.links.push({ 
          route: "global", 
          name: this.$tk("MenuDropdown.Admin") 
        })
      }

    },

    onMyProfile () {
      this.isOpen = false
      this.showProfileVisible = true
    },

    onUserClick () {
      this.isOpen = false
      this.$emit('userClick')
    },

    onLogout() {
      this.$store.dispatch("logout")
    }
    
  },

  created () {

    this.init()

    const handleEscape = e => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.isOpen = false
      }
    }

    document.addEventListener("keydown", handleEscape)
    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", handleEscape)
    })

  },

  // watch: {
  //   location: function () {
  //     this.init()
  //   }
  // }

}
</script>


<style>

.mobile-app-link-active { 
  @apply bg-green-100;
}

</style>