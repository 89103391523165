<template>
  <PSelect    
    :items="locations"    
    :value="value"
    :focus="focus"
    :label="label"
    :required="required"
    :searchable="true"
    @input="onInput"
    itemText="name"
    itemValue="id"
    v-bind="$props"
    v-slot="{ item }"      
  >
    <!-- {{ item.name }} -->

    <div class="font-semibold text-sm">
      {{ item.name }}
    </div>
    
    <div class="text-xs">
      {{ item.customerName }}
      <span v-if="customer.id !== item.customerId">
          ({{ item.poolingGroupId }}{{ item.poolingGroupId && item.customerNameCorporate ? ", " : "" }}{{ item.customerNameCorporate ? "Kommisjon" : "" }})
      </span>
    </div>

  </PSelect>
</template>

<script>

import http from "@/http"
import { 
  some, 
  filter, 
  find,  
  forEach,
  sortBy
} from "lodash"
import { mapGetters } from "vuex"

export default {

  name: "p-location-picker",
  
  props: {    
    value: {
      type: String,
      required: true
    },
    exclude: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    pooling: {
      type: Boolean,
      default: false
    },  
    corporate: {
      type: Boolean,
      default: false
    },  
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    error: {
      type: String,
      default: ""
    },
    focus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    excludeSr: {
      type: Boolean,
      default: false
    },
    palletReceipts: {
      type: Boolean,
      default: false
    },
    transactionType: {
      type: String,
      required: false
    }
  },
  
  data () {
    return {
      locations: []
    }
  },
  
  computed: {    
    ...mapGetters([
      "customer", 
      "location"
    ])    
  },

  methods: {

    async init () {

      let commission = []
      let pooling = []
      let corporate = []
      let customerLocations = []
      
      commission = await http.get("locations", { params: { transactiontype: this.transactionType } })

      if (this.pooling && this.location.poolingGroupId) {
        pooling = await http.get("locations", {
          params: {
            all: true,
            transactiontype: this.transactionType,
            poolingGroupId: this.location.poolingGroupId
          }
        })
      }

      if (this.corporate && this.location.customerIdCorporate) {          
        corporate = await http.get("locations", {
          params: {
            all: true,
            transactiontype: this.transactionType,
            customerId_Corporate: this.location.customerIdCorporate
          }
        })
      }

      if (this.palletReceipts) {        
        customerLocations = await http.get("locations", {
          params: {
            transactiontype: this.transactionType,
            customerId: this.customer.id
          }
        })
      }

      let arr = []

      forEach([].concat(commission, pooling, corporate, customerLocations), location => {            
        if (!some(arr, l => l.id === location.id) && (location.id !== this.location.id) && (location.statusId === 1)) {

          const isPooling = some(pooling, p => p.id === location.id)
          const isCorporate = some(corporate, c => c.id === location.id)

          location.isPooling = isPooling
          location.isCorporate = isCorporate

          arr.push(location)
        }
      })

      this.locations = sortBy(
        filter(arr, location => 
          (!this.palletReceipts || (this.palletReceipts && location.palletReceiptDays > 0)) &&
          !some(this.exclude, id => id === location.id) &&
          (
            !this.excludeSr || 
            (this.excludeSr && location.isSmartRetur === false) || 
            (this.location.isSmartRetur === true && location.isSmartRetur === true))
        ), l => l.name)  
        
      if (this.locations.length === 1) {               
        this.$emit("select", this.locations[0])
      }
    },

    onInput (value) {
      if (value) {          
        const location = find(this.locations, l => l.id === value)          
        this.$emit("select", location)
      }
    }
  },

  async created () {
    await this.init()
  }

}
</script>

