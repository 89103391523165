const routes = [
  {
    path: "dispatch",
    component: () =>
      import(
        /* webpackChunkName: "dispatchLayout" */ "@/areas/dispatch/layouts/DispatchLayout.vue"
      ),
    children: [      
      {
        path: "",
        name: "dispatch",
        redirect: { name: "dispatch.Dispatch" }
      },
      {
        path: "dispatch/:id?",
        name: "dispatch.Dispatch",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "dispatchDispatch" */ "@/areas/dispatch/views/Dispatch.vue"
          )
      },
      {
        path: "scheduled",
        name: "dispatch.Scheduled",
        component: () =>
          import(
            /* webpackChunkName: "dispatchScheduled" */ "@/areas/dispatch/views/Scheduled.vue"
          )
      } ,
      {
        path: "returns",
        name: "dispatch.Returns",
        component: () =>
          import(
            /* webpackChunkName: "dispatchReturns" */ "@/areas/dispatch/views/Returns.vue"
          )
      }     
    ]
  }
]

export default routes
