<template>

  <tbody :class="bodyClass">
    <template v-if="isExpanded">
      <tr>
        <td 
          class="selecter" 
          :rowspan="rowspan"
          @click="$emit('select', order)" 
          v-if="selectable">          
          <FontAwesomeIcon 
            :icon="['far', selected ? 'check-square' : 'square' ]" 
            size="lg" 
          />
        </td>
        <td 
          v-for="(col, index) in cols" 
          :key="index"
          :rowspan="col.nested ? 1 : rowspan"
          :class="{ 'empty': col.nested }"
          :align="col.align"
          :width="col.width"
          :colspan="col.nested ? col.nested.length : 1"
          :data-component="col.component"
          @click="onClick">
          <component 
            :is="col.component"
            v-if="!col.nested"
            :item="order"
            :component-config="col.componentConfig"
            :config="col.config"
            :value="col.value"
            :classes="col.classes"
          />
        </td>
        <td 
          class="expander"
          v-if="expandable"
          @click="isExpanded = !isExpanded" :rowspan="rowspan + 1"
          >
          <FontAwesomeIcon 
            :icon="['far', 'chevron-down' ]" 
            rotation="180" 
          />
        </td>
      </tr>          
      <tr v-for="(item, tindex) in order[nested]" :key="tindex">
        <td 
          v-for="(f, findex) in fields" 
          :key="findex" 
          :align="f.align"
          :width="f.width"
          :data-component="f.component"
          @click="onClick">
          <component
            :is="f.component"
            :component-config="f.componentConfig"
            :item="item"
            :nested="nested"
            :config="f.config"
            :value="f.value" 
          />
        </td>
      </tr>    
    </template>
    <tr v-else>
      <td 
        class="selecter" 
        @click="$emit('select', order)" 
        v-if="selectable"
        >
          <FontAwesomeIcon 
            :icon="['far', selected ? 'check-square' : 'square' ]" 
            size="lg" 
          />           
      </td>
      <td 
        v-for="(col, index) in allCols" 
        :key="index"                
        :align="col.align"
        :width="col.width" 
        :data-component="col.aggregate || col.component"       
        @click="onClick">
        <component 
          :is="col.aggregate || col.component"
          :component-config="col.componentConfig"
          :item="order"
          :nested="nested"
          :config="col.config"
          :value="col.value"
          :classes="col.classes"
        />
      </td>
      <td class="expander" @click="isExpanded = !isExpanded" v-if="expandable">
        <FontAwesomeIcon 
          :icon="['far', 'chevron-down' ]" 
        />
      </td>
    </tr>    
  </tbody>

</template>

<script>


import { get, forEach } from "lodash"

export default {
  name: "POrderGridRow",
  props: {
    cols: {
      type: Array,
      required: true
    },
    nested: {
      type: String,
      default: "transactions"
    },
    order: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0      
    },
    expandable: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  computed: {
    rowspan () {
      return this.order[this.nested].length + 1
    },
    allCols () {
      let arr = []
      forEach(this.cols, col => {
        if (col.nested) {
          arr = arr.concat(col.nested)
        } else {
          arr.push(col)
        }
      })      
      return arr
    },    
    fields () {
      let arr = []      
      forEach(this.cols, col => {
        if (col.nested) {
          arr = arr.concat(col.nested)
        }
      })
      return arr
    },
    bodyClass () {
      return {
        "selected": this.selected,
        "odd": this.index % 2 !== 0, 
        "is-expanded": this.isExpanded         
      }
    }
  },  
  methods: {
    onClick ($event) {
      
      let component = ""

      forEach($event.path, p => {
        component = get(p, "dataset.component", "")
        if (component) {
          return false
        }
      })

      this.$emit("click", component)
    }
  },
  watch: {
    expanded: {
      handler: function (val) {
        this.isExpanded = val
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

  tbody {
    cursor: pointer;
    border-bottom: 1px solid theme('colors.gray.300');
    background-color: theme('colors.gray.50')
  }

  tbody > tr > td { border-right: 1px solid theme('colors.gray.300'); }

  tbody.odd {
    background-color: theme('colors.gray.150')
  }

  tbody.selected,
  tbody.odd.selected {
    background-color: theme('colors.green.100');
    color: theme('colors.green.700');
  }

  /* tbody.is-expanded {
    background-color: theme('colors.orange.100');
    border-bottom: 1px solid theme('colors.gray.300');
  } */

  tbody:hover {
    background-color: theme('colors.orange.200');
    /* border-color: theme('colors.orange.300');     */
  }

  td:not(.empty) { 
    margin: 0; 
    padding: 0 theme('spacing.4');
    /* border-right: 1px solid theme('colors.gray.300'); */
  }

  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
  }
  .component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  /* tbody:hover td {
    color: theme('colors.orange.800');
    border-color: theme('colors.orange.300');    
  } */

   tbody > tr:not(:first-child):hover td {
    background-color: theme('colors.orange.100');
  }

  .expander > * { visibility: hidden }

  tbody:hover .expander > * { visibility: visible }

</style>

